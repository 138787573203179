import { StyleSheet, css } from "aphrodite";
import { Dialog, Button } from "@mui/material";

function PdfModal(props) {
  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggle}
      PaperProps={{
        sx: {
          width: "25%",
          maxHeight: 350,
        },
      }}
    >
      <div>
        <p className={css(styles.text)}>Layout do documento</p>
      </div>
      <div className={css(styles.buttonContainer)}>
        <button className={css(styles.btn)} onClick={props.retrato}>
          RETRATO
        </button>
        <button className={css(styles.btn)} onClick={props.paisagem}>
          PAISAGEM
        </button>
      </div>
    </Dialog>
  );
}

export default PdfModal;

const styles = StyleSheet.create({
  title: {
    fontFamily: "Poppins",
    textAlign: "center",
    fontWeight: 700,
    marginBottom: -5,
  },
  text: {
    fontFamily: "Poppins",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "1.2rem",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 4,
    marginTop: "-0.5rem",
    cursor: "pointer",

    padding: "5px 8px 5px 8px",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
});
