import Select from "react-select";

import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/muiComponents.css";

function FilterComp(props) {
  const customStyles = {
    container: (base) => ({
      ...base,
      marginTop: window.screen.width > 546 ? "-0.3rem" : "",
    }),
    placeholder: (base) => ({
      ...base,
      // marginTop: -2,
      color: "#cacaca",
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    input: (base) => ({
      ...base,
      marginTop: -2,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    menuList: (base) => ({
      ...base,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    multiValue: (base) => ({
      ...base,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
  };

  let itens;
  if (props.eqpList) {
    itens = props.eqpList;
  }

  return (
    <Grid
      container
      wrap="wrap"
      spacing={window.screen.width > 546 ? 2 : 1}
      style={{
        marginBottom: "1rem",
        paddingBottom: "1.5rem",
        borderBottom: "solid 0.5px #eee",
        justifyContent: "center",
      }}
    >
      <Grid item xs={12} sm={5} md={7.4}>
        <Select
          name="clientes"
          placeholder="Cliente"
          isMulti
          options={props.clienteList}
          value={props.cliValue}
          onChange={(ev) => props.cliSet(ev)}
          noOptionsMessage={() => "Sem resultados"}
          styles={{
            container: (base) => ({
              ...base,
              marginTop: window.screen.width > 546 ? "0.5rem" : "",
            }),
            placeholder: (base) => ({
              ...base,
              // marginTop: -2,
              color: "#cacaca",
              // fontFamily: "Poppins",
              fontSize: 12,
            }),
            input: (base) => ({
              ...base,
              marginTop: -2,
              // fontFamily: "Poppins",
              fontSize: 12,
            }),
            menuList: (base) => ({
              ...base,
              // fontFamily: "Poppins",
              fontSize: 12,
            }),
            multiValue: (base) => ({
              ...base,
              // fontFamily: "Poppins",
              fontSize: 12,
            }),
          }}
        ></Select>
      </Grid>
      <Grid item xs={6} sm={2} md={1.3}>
        <DatePicker
          label="Início"
          sx={{
            width: "100%",
            marginTop: "0.5rem",
            "& .MuiInputBase-root": {
              color: "#4b4b4b",
              height: "2.35rem",
              // fontFamily: "Poppins",
              fontSize: 10,
            },
            "& .MuiFormLabel-root": {
              color: "#cacaca",
              // marginTop: -0.2,
              // fontFamily: "Poppins",
              fontSize: 12,
              zIndex: 0,
            },
          }}
          slotProps={{ textField: { size: "small" } }}
          value={props.iniValue}
          views={props.periodo}
          onChange={(ev) => props.iniSet(ev)}
        />
      </Grid>
      <Grid item xs={6} sm={2} md={1.3}>
        <DatePicker
          label="Fim"
          sx={{
            width: "100%",
            marginTop: "0.5rem",
            "& .MuiInputBase-root": {
              color: "#4b4b4b",
              height: "2.35rem",
              // fontFamily: "Poppins",
              fontSize: 10,
            },
            "& .MuiFormLabel-root": {
              color: "#cacaca",
              // marginTop: -0.2,
              // fontFamily: "Poppins",
              fontSize: 12,
              zIndex: 0,
            },
          }}
          slotProps={{ textField: { size: "small" } }}
          value={props.fimValue}
          views={props.periodo}
          onChange={(ev) => props.fimSet(ev)}
        ></DatePicker>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <TextField
          name="referencia"
          id="referencia"
          placeholder={"Referência"}
          value={props.refValue}
          onChange={(ev) => props.refSet(ev.target.value)}
          type="search"
          size="small"
          fullWidth
          className={css(styles.input)}
          sx={{
            marginTop: window.screen.width > 546 ? "0.5rem" : "",
            "& .MuiInputBase-root": {
              height: 38,
              // fontFamily: "Poppins",
              fontSize: 12,
            },
          }}
        ></TextField>
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={3}>
        <Select
          name="pagamentos"
          placeholder="Condição de pagamento"
          isMulti
          options={props.pagList}
          value={props.pagValue}
          onChange={(ev) => props.pagSet(ev)}
          styles={customStyles}
          noOptionsMessage={() => "Sem resultados"}
        ></Select>
      </Grid>
      <Grid item xs={12} sm={6} md={2} lg={2.5}>
        <Select
          name="tipoRef"
          placeholder="Tipo de referência"
          isMulti
          options={props.tipoRefList}
          value={props.tipoRefValue}
          onChange={(ev) => props.tipoRefSet(ev)}
          styles={customStyles}
          noOptionsMessage={() => "Sem resultados"}
        ></Select>
      </Grid>
      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Select
          name="estabelecimentos"
          placeholder="Estabelecimentos"
          isMulti
          options={props.estList}
          value={props.estValue}
          onChange={(ev) => props.estSet(ev)}
          styles={customStyles}
          noOptionsMessage={() => "Sem resultados"}
        ></Select>
      </Grid>
      <Grid item xs={12} sm={4} md={2} lg={2}>
        <Select
          name="equipes"
          placeholder={
            itens[0] && itens.length <= 1 ? itens[0].label : "Equipes"
          }
          isMulti
          options={props.eqpList}
          value={props.eqpValue}
          onChange={(ev) => props.eqpSet(ev)}
          isDisabled={itens && itens.length <= 1 ? true : false}
          styles={customStyles}
          noOptionsMessage={() => "Sem resultados"}
        ></Select>
      </Grid>
      <Grid
        item
        xs={10}
        sm={3}
        md={3}
        lg={2}
        style={{ marginTop: window.screen.width > 546 ? "-0.3rem" : "" }}
      >
        <RadioGroup
          row
          name="order-group"
          defaultValue="quantidade"
          value={props.ordValue}
          onChange={props.ordSet}
          style={{
            borderStyle: "solid",
            borderRadius: 5,
            borderColor: "#cccccc",
            borderWidth: 1.5,
            paddingLeft: 15,
            height: "98%",
            justifyContent: "space-between",
          }}
        >
          <FormControlLabel
            value="quantidade"
            control={<Radio size="small" />}
            label="Quantidade"
            sx={{
              "& .MuiTypography-root": {
                color: "#4b4b4b",
                // fontFamily: "Poppins",
                fontSize: 12,
                marginTop: -0.2,
              },
              "& .MuiButtonBase-root": {
                marginTop: -0.2,
              },
            }}
          />
          <FormControlLabel
            value="valor"
            control={<Radio size="small" />}
            label="Valor"
            sx={{
              "& .MuiTypography-root": {
                color: "#4b4b4b",
                // fontFamily: "Poppins",
                fontSize: 12,
                marginTop: -0.2,
              },
              "& .MuiButtonBase-root": {
                marginTop: -0.2,
              },
            }}
          />
        </RadioGroup>
      </Grid>

      <Grid
        item
        xs={2}
        sm={1}
        md={1}
        lg={0.5}
        style={{ marginTop: window.screen.width > 546 ? "-1.5rem" : "-1.2rem" }}
      >
        <button
          onClick={() => props.search()}
          className={props.loading ? css(styles.btnDisabled) : css(styles.btn)}
          title="Buscar"
          disabled={props.loading}
        >
          <SearchOutlinedIcon />
        </button>
      </Grid>
      <p className={css(styles.erro)}>{props.erro}</p>
    </Grid>
  );
}

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.21rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.21rem",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
});

export default FilterComp;
