import React from "react";
import { useEffect } from "react";
import {
  Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from "react-router-dom";

import MainLayout from "./components/layout/MainLayout";
import LoginPage from "./pages/login/Login";
import MainPage from "./pages/main/mainPage";
import Produtos from "./pages/produtos/Produtos";
import ProdEdit from "./pages/produtos/ProdEdit";
import VendasEqp from "./pages/vendas/equipes/VendasEqp";
import VendasRef from "./pages/vendas/referencia/VendasRef";
import RefCurvaABC from "./pages/vendas/curvaABC_referencia/refCurvaABC";
import CliCurvaABC from "./pages/vendas/curvaABC_clientes/cliCurvaABC";
import MovimentoFinanceiro from "./pages/vendas/movimento_financeiro/MovimentoFinanceiro";
import Estabelecimentos from "./pages/vendas/estabelecimentos/Estabelecimentos";
import AutorizaVendas from "./pages/vendas/autorizacao_de_vendas/autorizaVendas";
import Vendedor from "./pages/vendas/vendedor/Vendedor";
import ConfiguracoesUsuario from "./pages/settings/ConfiguracoesUsuario";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";

function App() {
  const location = useLocation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route element={<MainLayout />}>
          <Route path="/main" element={<MainPage />} />
          <Route path="/produtos" element={<Produtos />} />
          <Route path="/produtos-editar/:codigo" element={<ProdEdit />} />
          <Route path="/vendas/equipe" element={<VendasEqp />} />
          <Route
            path="/vendas/estabelecimentos"
            element={<Estabelecimentos />}
          />
          <Route
            path="/vendas/meiopagamento"
            element={<MovimentoFinanceiro />}
          />
          <Route path="/vendas/referencia" element={<VendasRef />} />
          <Route path="/vendas/curvaABC-referencia" element={<RefCurvaABC />} />
          <Route path="/vendas/curvaABC-clientes" element={<CliCurvaABC />} />
          <Route path="/vendas/vendedor" element={<Vendedor />} />
          <Route
            path="/vendas/autorizacao-de-vendas"
            element={<AutorizaVendas />}
          />
          {/* <Route
          path="/configuracoes-usuario"
          element={<ConfiguracoesUsuario />}
        /> */}
        </Route>
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
