import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ConfirmaAut from "./ConfirmaAut";
import ErroAut from "./ErroAut";

import { StyleSheet, css } from "aphrodite";
import {
  Card,
  CardContent,
  Checkbox,
  Dialog,
  Button,
  DialogTitle,
  Typography,
} from "@mui/material";

import { DataGrid, ptBR } from "@mui/x-data-grid";

import axios from "axios";

import { headers } from "../core/store/localStorage";
import errorHandler from "../core/store/errorHandler";

import "../../assets/styles/muiComponents.css";
import "../../assets/styles/tabela.css";

const API_URL = localStorage.getItem("apiUrl");

const asyncLocalStorage = {
  async getItem(key) {
    await null;
    return localStorage.getItem(key);
  },
};

function DetailsModal(props) {
  const [userId, setCurrentUserID] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [selectedHeadRows, setSelectedHeadRows] = useState([]);
  const [selectedInfoRows, setSelectedInfoRows] = useState([]);

  const dt = new Date().toLocaleString().split(",")[0];

  console.log(API_URL);

  useEffect(() => {
    getUserID();
  }, []);

  let general;
  let headerTable;
  let detailTable;
  let name;

  if (props.general) {
    general = props.general;
  }
  if (props.table1) {
    headerTable = props.table1;
  }
  if (props.table2) {
    detailTable = props.table2;
  }
  if (props.cliente) {
    name = props.cliente;
  }
  console.log(general);

  let qtdeLinhas1 = "";
  let qtdeLinhas2 = "";

  if (headerTable && headerTable !== "0") {
    qtdeLinhas1 = headerTable.length;
    if (qtdeLinhas1 % 2 === 0) {
      qtdeLinhas1 += 1;
    }
  }

  if (detailTable && detailTable !== "0") {
    qtdeLinhas2 = detailTable.length;
    if (qtdeLinhas2 % 2 === 0) {
      qtdeLinhas2 += 1;
    }
  }

  function getUserID() {
    asyncLocalStorage.getItem("userId").then((response) => {
      setCurrentUserID(response);
    });
  }

  const head = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,

      "Content-Type": "application/json",
    },
  };

  const heads1 = [
    {
      field: "oc",
      headerName: "Ocorrência",
      flex: 2,
      headerClassName: "datagrid_head",
    },
    {
      field: "tipoPendencia",
      headerName: "Tipo de Pendência",
      flex: 1,
      headerClassName: "datagrid_head",
    },
    {
      field: "valPadrao",
      headerName: "Valor Padrão",
      flex: 1,
      headerClassName: "datagrid_head",
    },
    {
      field: "valInformado",
      headerName: "Valor Informado",
      flex: 1,
      headerClassName: "datagrid_head",
    },
  ];

  const heads2 = [
    {
      field: "ref",
      headerName: "Referência",
      flex: 1,
      headerClassName: "datagrid_head",
    },
    {
      field: "oc",
      headerName: "Ocorrência",
      flex: 2,
      headerClassName: "datagrid_head",
    },
    {
      field: "valPadrao",
      headerName: "Valor Padrão",
      flex: 1,
      headerClassName: "datagrid_head",
    },
    {
      field: "valInformado",
      headerName: "Valor Informado",
      flex: 1,
      headerClassName: "datagrid_head",
    },
  ];

  const handleHeadSelectionChange = (newSelection) => {
    setSelectedHeadRows(newSelection);
  };
  const handleInfoSelectionChange = (newSelection) => {
    setSelectedInfoRows(newSelection);
  };

  const onHeadRowsSelectionHandler = (ids) => {
    console.log(ids);
    console.log(general);

    handleHeadSelectionChange(ids);

    general.ocorrenciaCabecalho.forEach((item) => {
      if (item.autorizacaoCabecalho.autorizadorId === "") {
        console.log(item.id);
        if (ids.includes(item.id)) {
          item.autorizacaoCabecalho.autorizadorId = userId;
          item.autorizacaoCabecalho.data = dt;
        } else {
          item.autorizacaoCabecalho.autorizadorId = "";
          item.autorizacaoCabecalho.data = "";
        }
      }
    });
  };

  const onDetailsRowsSelectionHandler = (ids) => {
    console.log(ids);
    console.log(general);

    handleInfoSelectionChange(ids);

    general.ocorrenciaItem.forEach((item) => {
      if (item.autorizacaoItem.autorizadorId === "") {
        if (ids.includes(item.id)) {
          item.autorizacaoItem.autorizadorId = userId;
          item.autorizacaoItem.data = dt;
        } else {
          item.autorizacaoItem.autorizadorId = "";
          item.autorizacaoItem.data = "";
        }
      }
    });
  };

  let testeArr = [];
  let idRemove;

  // function handleCheck(info) {
  //   idRemove = info.id;

  //   if (!testeArr.includes(idRemove)) {
  //     testeArr.push(info);
  //   } else {
  //     testeArr.filter((dat) => !dat.includes(idRemove));
  //   }

  //   console.log(testeArr);
  // }

  const send = () => {
    const stat = { status: "K" };

    let cabecalho = true;
    let linhas = true;

    let listCabecalho = [];
    let listLinhas = [];

    if (general.ocorrenciaCabecalho.length > 0) {
      general.ocorrenciaCabecalho.forEach((item) => {
        console.log(item.autorizacaoCabecalho.autorizadorId);

        if (item.autorizacaoCabecalho.autorizadorId === "") {
          item.autorizacaoCabecalho.autorizadorId = "vazio";
        }

        listCabecalho.push(item.autorizacaoCabecalho.autorizadorId);

        const found = listCabecalho.find((element) => element === "vazio");
        console.log(found);

        if (found === "vazio") {
          cabecalho = false;
        }
      });
    }

    if (general.ocorrenciaItem.length > 0) {
      general.ocorrenciaItem.forEach((item) => {
        console.log(item.autorizacaoItem.autorizadorId);

        if (item.autorizacaoItem.autorizadorId === "") {
          item.autorizacaoItem.autorizadorId = "vazio";
        }

        listLinhas.push(item.autorizacaoItem.autorizadorId);

        const found = listLinhas.find((element) => element === "vazio");
        console.log(found);

        if (found === "vazio") {
          linhas = false;
        }
      });
    }

    if (
      general.ocorrenciaCabecalho.length > 0 &&
      general.ocorrenciaItem.length > 0
    ) {
      if (cabecalho === true && linhas === true) {
        console.log(stat);

        axios
          .post(`${API_URL}/orcamentopendente`, general, head)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            errorHandler(err);
          });

        axios
          .put(`${API_URL}/orcamento/${general.id}`, stat, head)
          .then((response) => {
            console.log(response);
            console.log(stat);
            setConfirmOpen(true);
          })
          .catch((err) => {
            setErrorOpen(true);
            console.log("ERRO");
            errorHandler(err);
          });
      } else {
        axios
          .post(`${API_URL}/orcamentopendente`, general, head)
          .then((response) => {
            console.log(response);
            console.log(stat);
            setConfirmOpen(true);
          })
          .catch((err) => {
            setErrorOpen(true);
            console.log("ERRO");
            errorHandler(err);
          });
      }
    } else if (general.ocorrenciaItem.length === 0) {
      if (cabecalho === true) {
        console.log(stat);

        axios
          .post(`${API_URL}/orcamentopendente`, general, head)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            errorHandler(err);
          });

        axios
          .put(`${API_URL}/orcamento/${general.id}`, stat, head)
          .then((response) => {
            console.log(response);
            console.log(stat);
            setConfirmOpen(true);
          })
          .catch((err) => {
            setErrorOpen(true);
            console.log("ERRO");
            errorHandler(err);
          });
      } else {
        axios
          .post(`${API_URL}/orcamentopendente`, general, head)
          .then((response) => {
            console.log(response);
            console.log(stat);
            setConfirmOpen(true);
          })
          .catch((err) => {
            setErrorOpen(true);
            console.log("ERRO");
            errorHandler(err);
          });
      }
    } else {
      if (linhas === true) {
        console.log(stat);

        axios
          .post(`${API_URL}/orcamentopendente`, general, head)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            errorHandler(err);
          });

        axios
          .put(`${API_URL}/orcamento/${general.id}`, stat, head)
          .then((response) => {
            console.log(response);
            console.log(stat);
            setConfirmOpen(true);
          })
          .catch((err) => {
            setErrorOpen(true);
            console.log("ERRO");
            errorHandler(err);
          });
      } else {
        axios
          .post(`${API_URL}/orcamentopendente`, general, head)
          .then((response) => {
            console.log(response);
            console.log(stat);
            setConfirmOpen(true);
          })
          .catch((err) => {
            setErrorOpen(true);
            console.log("ERRO");
            errorHandler(err);
          });
      }
    }
  };

  const closeError = () => {
    setErrorOpen(false);
  };

  const voltar = () => {
    window.location.reload();
  };

  let identifiers;

  if (general) {
    identifiers = general.id.split("_");
    console.log(identifiers[0]);
  }

  function generateRandom() {
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 1, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={props.close}
        PaperProps={{
          sx: {
            minWidth: 1200,
            height: 550,
            paddingRight: 2,
            paddingLeft: 2,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle className={css(styles.title)}>
            Detalhes da venda - Orçamento Nº {general ? identifiers[1] : ""}{" "}
            {name ? ` - ${name}` : ""}
          </DialogTitle>
          <DialogTitle className={css(styles.estab)}>
            Estabelecimento - {general ? identifiers[0] : ""}
          </DialogTitle>
        </div>
        <hr
          style={{
            color: "#eee",
            marginTop: "-0.6rem",
            marginBottom: "0.5rem",
            width: "100%",
          }}
        />

        {headerTable && headerTable.length !== 0 ? (
          <Box sx={{ width: "100%" }}>
            <DataGrid
              rows={headerTable}
              rowHeight={25}
              columns={heads1}
              columnHeaderHeight={30}
              // getRowId={(row) => generateRandom()}
              hideFooter
              checkboxSelection
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              slotProps={{
                row: {
                  style: {
                    color: "#4b4b4b",
                    fontSize: 12,
                  },
                },
              }}
              onRowSelectionModelChange={(ids) =>
                onHeadRowsSelectionHandler(ids)
              }
              getRowClassName={(params) =>
                params.row.autorizado
                  ? "locked_row"
                  : params.indexRelativeToCurrentPage % 2 === 0
                  ? "odd_row"
                  : "even_row"
              }
              isRowSelectable={(params) => !params.row.autorizado}
            />
          </Box>
        ) : (
          ""
        )}

        {detailTable && detailTable.length !== 0 ? (
          <Box
            sx={{ width: "100%", marginTop: headerTable.length !== 0 ? 2 : "" }}
          >
            <DataGrid
              rows={detailTable}
              rowHeight={25}
              columns={heads2}
              columnHeaderHeight={30}
              // getRowId={(row) => generateRandom()}
              hideFooter
              checkboxSelection
              disableRowSelectionOnClick
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              slotProps={{
                row: {
                  style: {
                    color: "#4b4b4b",
                    fontSize: 12,
                  },
                },
              }}
              onRowSelectionModelChange={(ids) =>
                onDetailsRowsSelectionHandler(ids)
              }
              getRowClassName={(params) =>
                params.row.autorizado
                  ? "locked_row"
                  : params.indexRelativeToCurrentPage % 2 === 0
                  ? "odd_row"
                  : "even_row"
              }
              isRowSelectable={(params) => !params.row.autorizado}
            />
          </Box>
        ) : (
          ""
        )}

        <div style={{ height: "100%" }}></div>

        <div className={css(styles.buttonContainer)}>
          <Button
            variant="outlined"
            color="success"
            onClick={() => send()}
            style={{ margin: 5 }}
            disabled={
              selectedHeadRows.length > 0 || selectedInfoRows.length > 0
                ? false
                : true
            }
          >
            Autorizar
          </Button>
          {/* <Button
            variant="outlined"
            color="error"
            onClick={props.toggle}
            style={{ margin: 5 }}
          >
            Reprovar
          </Button> */}
          <Button
            variant="outlined"
            color="info"
            onClick={props.close}
            style={{ margin: 5 }}
          >
            Voltar
          </Button>
        </div>

        <ConfirmaAut modalOpen={confirmOpen} toggle={voltar} />
        <ErroAut modalOpen={errorOpen} toggle={closeError} />
      </Dialog>
    </>
  );
}

export default DetailsModal;

const styles = StyleSheet.create({
  title: {
    // fontFamily: "Poppins",
    textAlign: "start",
    fontSize: 18,
    fontWeight: 700,
    // marginLeft: -25,
  },
  estab: {
    // fontFamily: "Poppins",
    textAlign: "end",
    fontSize: 18,
    fontWeight: 700,
    // marginRigth: -50,
  },
  text: {
    // fontFamily: "Poppins",
    textAlign: "center",
  },
  subtitle: {
    color: "#F2474A",
    fontSize: 13,
    fontWeight: 500,
    // fontFamily: "Poppins",
    textAlign: "center",
    marginTop: -10,
  },
  buttonContainer: {
    position: "static",
    bottom: 1,

    marginTop: "2%",
    marginBottom: "2%",
    display: "flex",
    justifyContent: "center",
  },
  box: {
    borderRadius: 6,
    padding: 5,
    boxShadow: "0px 1px 2px #808080",
  },
});
