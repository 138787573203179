import * as React from "react";
import { Drawer, DrawerHeader } from "./Drawer";
import DrawerItemsList from "./DrawerItemsList";
import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";

import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import { AppBar } from "./AppBar";

import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Colors from "../constants/Colors";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import icon from "../../assets/imgs/SIGAicon.png";

import { StyleSheet, css } from "aphrodite";
import "../../assets/styles/dropdown.css";

import { styled } from "@mui/material/styles";

const asyncLocalStorage = {
  async getItem(key: any) {
    await null;
    return localStorage.getItem(key);
  },
};

// const currentUser = localStorage.getItem("userName");
// const currentEmp = localStorage.getItem("empName");

export default function MainLayout() {
  // console.log(currentUser);
  // console.log(currentEmp);

  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openQuerySubMenu, setOpenQuerySubmenu] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>("");
  const [currentEmp, setCurrentEmp] = useState<any>("");

  const theme = useTheme();

  useEffect(() => {
    getUser();
    getEmp();
  }, []);

  function getUser() {
    asyncLocalStorage.getItem("userName").then((response) => {
      setCurrentUser(response);
    });
  }

  function getEmp() {
    asyncLocalStorage.getItem("empName").then((response) => {
      setCurrentEmp(response);
    });
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openBtn = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    console.log("menu aberto");
  };
  const handleClose = () => {
    setAnchorEl(null);
    console.log("opção clicada");
  };

  // function handleSelect(e) {
  //   console.log(e);
  //   const returnInfo = e.split(",");
  //   const returnId = returnInfo[0];
  //   const returnName = returnInfo[1];

  //   const formatId = Number(returnId);

  //   setEmpName(returnName);
  //   setEmpId(formatId);
  //   localStorage.setItem("empresa_id", returnId.replace(/;/g, ","));

  //   getAllGroups();
  //   if (groups[0].id) {
  //     navigate(`/grupo/${groups[0].id}`);
  //   } else {
  //     navigate("/main");
  //   }
  // }

  const IconButton = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      marginLeft: "0.1rem",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-0.3rem",
    },
  }));

  console.log(window.screen.width);
  console.log(open);

  return (
    <Box style={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: Colors.primary, height: "2.5rem" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            // edge="start"
            sx={{
              marginRight: "2.5rem",
              marginTop: window.screen.width < 546 ? "-0.5rem" : "0.4rem",
              ...(open && { display: "none" }),
              cursor: "pointer",
            }}
          >
            <MenuIcon />
          </IconButton>

          {window.screen.width > 546 ? (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                // marginTop: "-1.5rem",
                marginLeft: "-2rem",
              }}
            >
              <img
                src={icon}
                style={{
                  // position: "absolute",
                  // top: 3,
                  // right: 20,
                  width: "20%",
                  marginRight: "0.5rem",
                }}
              />
              <Link
                to={"/main"}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                SIGA
              </Link>
            </Typography>
          ) : window.screen.width < 546 && !open ? (
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "-1rem",
                marginLeft: "-2rem",
              }}
            >
              <img
                src={icon}
                style={{
                  // position: "absolute",
                  // top: 3,
                  // right: 20,
                  width: "20%",
                  marginRight: "0.5rem",
                }}
              />
              <Link
                to={"/main"}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                SIGA
              </Link>
            </Typography>
          ) : (
            ""
          )}

          <div className={css(styles.userIcon)}>
            {/* <Link
              style={{ textDecoration: "none" }}
              to={"/configuracoes-usuario"}
            > */}
            <p className={css(styles.empInfo)}>
              {currentEmp ? currentEmp.toUpperCase() : "EMPRESA"}
            </p>
            <p className={css(styles.userInfo)}>
              {currentUser ? currentUser : "Usuário"}
            </p>
            {/* </Link> */}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            style={{
              position: "absolute",
              top: "9px",
              marginRight: 4,
              cursor: "pointer",
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <DrawerItemsList
          open={open}
          openDialog={openDialog}
          openQuerySubMenu={openQuerySubMenu}
          toggleDialog={() => setOpenDialog(true)}
          toggleQueryMenu={() => setOpenQuerySubmenu(!openQuerySubMenu)}
        />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}

const styles = StyleSheet.create({
  userIcon: {
    position: "absolute",
    right: "2.5rem",
    top: "0.23rem",
    marginBottom: "-2rem",
    // alignItems: "flex-end",
  },
  userInfo: {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Poppins",
    textAlign: "right",
    transitionDuration: "0.2s",
    fontWeight: 500,
    marginTop: "-1rem",
    marginRight: "-0.8rem",

    // ":hover": {
    //   color: "#eee",
    // },
  },
  empInfo: {
    color: "#fff",
    fontSize: 14,
    fontFamily: "Poppins",
    textAlign: "right",
    transitionDuration: "0.2s",
    fontWeight: 600,
    marginTop: "-0.2rem",
    marginRight: "-0.8rem",
  },
});
