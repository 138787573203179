import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

// import { ordersApi } from '../api/ordersApi';
// import { queriesApi } from '../api/queriesApi';
// import { clientsApi } from '../api/clientsApi';
// import { productsApi } from '../api/productsApi';
// import { categoryApi } from '../api/categoryApi';
// import { orderTypeApi } from '../api/orderTypeApi';
// import { orderPhaseApi } from '../api/orderPhaseApi';
// import { tenantConfigApi } from '../api/tenantConfigApi';
// import { orderSituationApi } from '../api/orderSituationApi';
// import { productPriceTableApi } from '../api/productPriceTableApi';
// import { paymentConditionsApi } from '../api/paymentConditionsApi';

import authSlice from "./auth/auth-slice";
// import utilSlice from './util/util-slice';
// import orderSlice from './order/order-slice';
import apiUrlSlice from "./api/api-url-slice";
// import tenantSlice from './tenant/tenant-slice';
// import citiesSlice from './address/cities-slice';
// import clientSlice from './client/client-slice';
// import exitPageSlice from './exitPage/exit-page-slice';
// import readabilitySlice from './readability/readability-slice';
// import orderSearchSlice from './search/order-search-slice';
import visualizationSlice from "./visualization/visualization-slice";
// import clientFormErrorSlice from './error/client-form-error-slice';
// import orderQueryParamsSlice from './queries/order-query-params-slice';
// import billingQueryParamsSlice from './queries/billings-query-params-slice';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    // util: utilSlice.reducer,
    // order: orderSlice.reducer,
    apiUrl: apiUrlSlice.reducer,
    // client: clientSlice.reducer,
    // cities: citiesSlice.reducer,
    // exitPage: exitPageSlice.reducer,
    // readability: readabilitySlice.reducer,
    // orderSearch: orderSearchSlice.reducer,
    visualization: visualizationSlice.reducer,
    // clientFormError: clientFormErrorSlice.reducer,
    // orderQueryParams: orderQueryParamsSlice.reducer,
    // billingQueryParams: billingQueryParamsSlice.reducer,
    // tenantConfiguration: tenantSlice.reducer,
    // [ordersApi.reducerPath]: ordersApi.reducer,
    // [queriesApi.reducerPath]: queriesApi.reducer,
    // [clientsApi.reducerPath]: clientsApi.reducer,
    // [productsApi.reducerPath]: productsApi.reducer,
    // [categoryApi.reducerPath]: categoryApi.reducer,
    // [orderTypeApi.reducerPath]: orderTypeApi.reducer,
    // [orderPhaseApi.reducerPath]: orderPhaseApi.reducer,
    // [tenantConfigApi.reducerPath]: tenantConfigApi.reducer,
    // [orderSituationApi.reducerPath]: orderSituationApi.reducer,
    // [paymentConditionsApi.reducerPath]: paymentConditionsApi.reducer,
    // [productPriceTableApi.reducerPath]: productPriceTableApi.reducer,
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(
  //     ordersApi.middleware,
  //     queriesApi.middleware,
  //     clientsApi.middleware,
  //     productsApi.middleware,
  //     categoryApi.middleware,
  //     orderTypeApi.middleware,
  //     orderPhaseApi.middleware,
  //     tenantConfigApi.middleware,
  //     orderSituationApi.middleware,
  //     paymentConditionsApi.middleware,
  //     productPriceTableApi.middleware
  //   ),
});

setupListeners(store.dispatch);

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
