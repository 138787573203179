import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useDrivePicker from "react-google-drive-picker/dist";
import { FileUploader } from "react-drag-drop-files";

import BotaoConfirmar from "../../components/buttons/btnConfirma";
import BotaoVoltar from "../../components/buttons/btnVoltar";
import DeletaModal from "../../components/modals/DeleteModal";
import SucessoModal from "../../components/modals/SucessoModal";
import Carregando from "../../components/modals/Carregando";

import { StyleSheet, css } from "aphrodite";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

import axios from "axios";
import { headers } from "../../components/core/store/localStorage";
import errorHandler from "../../components/core/store/errorHandler";
import { Link, useMatch } from "react-router-dom";

import { Box } from "@mui/material";
import { Grid } from "@mui/material";

import {
  FormGroup,
  Input,
  Label,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import "../../assets/styles/text.css";
import "../../assets/styles/botoes.css";
import "../../assets/styles/imgPicker.css";

const ProdEdit = (props) => {
  const [openPicker, authResponse] = useDrivePicker();

  const { state } = useLocation();
  const location = useLocation();

  let slctCode = "";
  let slctNome = "";
  let slctUn = "";

  if (state) {
    slctCode = state.codigo;
    slctNome = state.nome;
    slctUn = state.un;
  } else {
    slctCode = "";
    slctNome = "";
    slctUn = "";
  }

  const ini = (
    <td style={{ fontSize: 14 }}>Escolha uma imagem para ser inserida</td>
  );

  const [erro, setErro] = useState(null);
  const [imgList, setImgList] = useState("");
  const [urlList, setUrlList] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [sucessoOpen, setSucessoOpen] = useState(false);
  const [del, setDel] = useState("");
  const [loading, setLoading] = useState(false);
  const [pckErr, setPckErr] = useState(false);
  const [label, setLabel] = useState(ini);
  const [imgIndex, setImgIndex] = useState(-1);

  const API_URL = localStorage.getItem("apiUrl");
  const insert = localStorage.getItem("insert");
  const exclude = localStorage.getItem("delete");

  useEffect(() => {
    getPictures();
  }, [trigger]);

  function getPictures() {
    axios
      .get(`${API_URL}/produto/${slctCode}/imagem/all?page=1`, headers())
      .then((response) => {
        let resp = [];
        let urls = [];
        response.data.imagens.map((res, index) => {
          resp.push({
            nome: res.nome,
            id: res.imagemId,
            url: res.fileUrl,
            index: index,
          });
          urls.push({
            src: res.fileUrl,
          });
        });
        console.log(resp);
        setImgList(resp);
        setUrlList(urls);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  const retorna = () => {
    setModalOpen(!modalOpen);
  };

  function startDelete(deleteId) {
    setModalOpen(true);
    setDel(deleteId);
  }

  function deleteImg() {
    axios
      .delete(
        `${API_URL}/produto/${slctCode}/imagem?imagemId=${del}`,
        headers()
      )
      .catch((err) => {
        console.log(err);
      });
    setModalOpen(!modalOpen);
    setSucessoOpen(!sucessoOpen);
  }

  const voltar = () => {
    setSucessoOpen(!sucessoOpen);
    window.location.reload();
  };

  const fileTypes = ["JPG", "JPEG", "PNG"];

  const handleChange = (file) => {
    setLoading(true);
    console.log(loading);
    console.log(file);

    const head = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,

        "Content-Type": "multipart/form-data; boundary='file'",
      },
    };
    let formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${API_URL}/produto/${slctCode}/imagem`, formData, head)
      .then((response) => {
        console.log(response);
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setPckErr(true);
        setLabel(
          <td style={{ fontSize: 12 }}>
            Ocorreu um erro inesperado. Tente novamente.
          </td>
        );

        setLoading(false);
        errorHandler(err);
      });
  };

  function sizeInv() {
    setPckErr(true);
    setLabel(
      <td style={{ fontSize: 12 }}>
        A imagem ultrapassa o limite de 15MB! Escolha outra imagem.
      </td>
    );
  }

  function typeInv() {
    setPckErr(true);
    setLabel(
      <td style={{ fontSize: 12 }}>
        Tipo de arquivo inválido! Escolha uma imagem com uma das extensões
        sugeridas.
      </td>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container wrap="wrap">
          <Grid item xs={11}>
            <h3 className={css(styles.pageTitle)}>Informações do Produto</h3>
          </Grid>
        </Grid>
        <hr
          style={{ color: "#eee", marginTop: "-0.5rem", marginBottom: "1rem" }}
        />

        <Grid container wrap="wrap" spacing={2}>
          <Grid item xs={12} md={2}>
            <FormGroup>
              <Label className={css(styles.label)} for="empresa">
                Código
              </Label>
              <Input
                type="text"
                value={slctCode}
                name="id"
                id="id"
                className={css(styles.fixedInput)}
                style={{ marginTop: "0.5rem" }}
                disabled={true}
              ></Input>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={4}>
            <FormGroup>
              <Label className={css(styles.label)} for="empresa">
                Nome
              </Label>
              <Input
                type="text"
                value={slctNome}
                name="id"
                id="id"
                className={css(styles.fixedInput)}
                style={{ marginTop: "0.5rem" }}
                disabled={true}
              ></Input>
            </FormGroup>
          </Grid>

          <Grid item xs={12} md={1}>
            <FormGroup>
              <Label className={css(styles.label)} for="empresa">
                U.M.
              </Label>
              <Input
                type="text"
                value={slctUn}
                name="id"
                id="id"
                className={css(styles.fixedInput)}
                style={{ marginTop: "0.5rem" }}
                disabled={true}
              ></Input>
            </FormGroup>
          </Grid>
        </Grid>

        {insert ? (
          <Grid
            container
            wrap="wrap"
            style={{ marginTop: "0.5rem", borderTop: "solid 0.5px #eee" }}
          >
            <Grid item xs={12} md={12}>
              <FileUploader
                handleChange={handleChange}
                name="file"
                label="Escolha uma imagem para ser inserida"
                success="Imagem enviada!"
                maxSize={15}
                onSizeError={sizeInv}
                onTypeError={typeInv}
                multiple={true}
                types={fileTypes}
              >
                <div
                  className={
                    pckErr ? css(styles.imgPickerErr) : css(styles.imgPicker)
                  }
                >
                  <AddPhotoAlternateOutlinedIcon
                    style={{
                      fontSize: 28,
                      marginRight: "1%",
                      // color: "#4b4b4b",
                    }}
                  />

                  <table style={{ width: "100%" }}>
                    <tr>
                      {label}
                      <td style={{ float: "right", fontSize: 12 }}>
                        JPG, JPEG, PNG
                      </td>
                    </tr>
                  </table>
                </div>
              </FileUploader>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          container
          wrap="wrap"
          style={{ marginTop: "0.5rem", borderTop: "solid 0.5px #eee" }}
        >
          <Grid item xs={12} md={12} style={{ marginTop: "1rem" }}>
            <h3 className={css(styles.subtitle)} style={{ fontSize: 14 }}>
              Galeria
            </h3>
          </Grid>
        </Grid>

        <Grid container wrap="wrap">
          <div className={css(styles.imgDisplay)}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {imgList === true || imgList.length > 0 ? (
                imgList.map((list) => {
                  let deleteId = list.id;

                  return (
                    <Grid item xs={12} style={{ marginRight: "3%" }}>
                      <div className={css(styles.imgContainer)}>
                        {insert ? (
                          <button
                            className={css(styles.dltBtn)}
                            onClick={() => startDelete(deleteId)}
                          >
                            <ClearOutlinedIcon
                              style={{
                                fontSize: 18,
                              }}
                            />
                          </button>
                        ) : (
                          ""
                        )}

                        <img
                          style={{ borderRadius: 10, objectFit: "cover" }}
                          width={150}
                          height={150}
                          src={list.url}
                          onClick={() => setImgIndex(list.index)}
                        />
                        <div
                          style={{
                            margin: "-0.6rem 0",
                            padding: "0 0.4rem",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 14,
                            }}
                            className="text"
                          >
                            {list.nome}
                          </p>
                        </div>
                      </div>
                    </Grid>
                  );
                })
              ) : (
                <p style={{ fontSize: 12 }}>
                  Não há imagens gravadas para esse produto.
                </p>
              )}
            </div>
          </div>
        </Grid>
      </Box>

      <p className={css(styles.erro)}>{erro}</p>
      {/* <AlteraModal modalOpen={modalOpen} toggle={retorna} /> */}

      <div
        style={{
          display: "flex",
          marginTop: "1rem",
          padding: "1rem 0",
          borderTop: "solid 0.5px #eee",
        }}
      >
        <Link to={"/produtos"} style={{ textDecoration: "none" }}>
          <BotaoVoltar />
        </Link>
      </div>

      <Lightbox
        index={imgIndex}
        open={imgIndex >= 0}
        close={() => setImgIndex(-1)}
        slides={urlList}
        carousel={{ finite: true }}
      />

      <DeletaModal modalOpen={modalOpen} toggle={deleteImg} close={retorna} />
      <SucessoModal modalOpen={sucessoOpen} toggle={voltar} />
      <Carregando modalOpen={loading} />
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#2b2b2b",
    marginTop: "-0.15rem",
  },
  subtitle: {
    // fontFamily: "Poppins",
    fontWeight: 600,
    color: "#2b2b2b",
    marginTop: "-0.15rem",
  },
  label: {
    // fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 8,
    // fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  fixedInput: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    padding: 8,
    // fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: "#E2E2E2",
    color: "#808080",
    width: "100%",
    borderRadius: 4,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
  },
  select: {
    marginBottom: "0.2rem",
    marginBottom: "1rem",
    // fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 400,
    width: "100%",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "left",
    fontWeight: 600,
  },
  imgDisplay: {
    padding: "1rem 0",
    marginTop: "-1rem",
    marginBottom: "-1rem",
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    // marginRight: "2%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#eee",
    width: 160,
    borderRadius: 10,
    padding: 5,
  },
  dltBtn: {
    borderRadius: "100%",
    width: 22,
    marginTop: -20,
    position: "relative",
    top: 25,
    left: 61,
    display: "flex",
    justifyContent: "center",
    alignContent: "center",

    color: "#fff",
    backgroundColor: "#cc5662",
    borderStyle: "solid",
    borderWidth: 0.4,
    borderColor: "#cc5662",
    transitionDuration: "0.4s",

    ":hover": {
      color: "#cc5662",
      borderColor: "#cc5662",
      backgroundColor: "rgba(255, 255, 255)",
    },
    ":active": {
      color: "#cc5662",
      borderColor: "#cc5662",
      backgroundColor: "rgba(255, 255, 255, 0.4)",
    },
  },
  btn: {
    backgroundColor: "#2CBA8D",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#2CBA8D",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.2rem 0.3rem 0 0.3rem",
    height: "70%",
    marginTop: "0.5rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#2CBA8D",
      backgroundColor: "transparent !important",
      borderColor: "#2CBA8D !important",
    },
    ":active": {
      color: "#2CBA8D",
      backgroundColor: "transparent !important",
      borderColor: "#2CBA8D !important",
      opacity: 0.6,
    },
  },
  imgPicker: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "0.2rem",
    padding: "0.8rem 1rem",

    color: "#4b4b4b",
    // fontFamily: "Poppins",

    borderStyle: "dashed",
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "#eee",

    transitionDuration: "0.2s",

    ":hover": {
      color: "#1786c0 !important",
      borderColor: "#1786c0",
    },
  },
  imgPickerErr: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    marginBottom: "0.2rem",
    padding: "0.8rem 1rem",

    color: "#F34456",
    // fontFamily: "Poppins",

    borderStyle: "dashed",
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "#F34456",

    transitionDuration: "0.2s",

    ":hover": {
      color: "#cc5662 !important",
      borderColor: "#cc5662",
    },
  },
});

export default ProdEdit;
