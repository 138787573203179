import React, { useEffect, useRef, useState } from "react";
import BotaoAlterar from "../../components/buttons/btnEdit";

import axios from "axios";

import {
  Box,
  Grid,
  TextField,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import "../../assets/styles/tabela.css";
import "../../assets/styles/toggleBtn.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { StyleSheet, css } from "aphrodite";
import Colors from "../../components/constants/Colors";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { headers } from "../../components/core/store/localStorage";
import errorHandler from "../../components/core/store/errorHandler";
const API_URL = localStorage.getItem("apiUrl");

const Produtos = () => {
  const id = window.location.href;
  let link = "";
  console.log(id);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");
  const filter = query.get("filtro");

  const [pg, setPg] = useState(link);
  const [produtos, setProdutos] = useState([]);
  const [pagination, setPagination] = useState("");
  const [toggle, setToggle] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const screen = useRef(window.innerWidth);
  const [loading, setLoading] = useState(false);

  if (id.includes("?page=")) {
    link = id.charAt(38);
    console.log(link);
  } else {
    link = "";
    console.log(link);
  }

  useEffect(() => {
    getProdutos();
    console.log(pg);
    console.log(produtos);
  }, [pg, toggle, search]);

  useEffect(() => {
    newPg();
  }, [page]);

  function getProdutos() {
    if (!API_URL) {
      window.location.reload();

      setLoading(true);
      axios
        .get(
          `${API_URL}/produto?page=${pg}&filtro=${search}&filtroSemImagem=${toggle}`,
          headers()
        )
        .then((response) => {
          setLoading(false);
          let resp = [];
          response.data.produtos.map((res) => {
            resp.push({
              codigo: res.codigo,
              nome: res.nome,
              unMedida: res.unidadeMedida,
              totalImg: res.totalImagem,
            });
          });
          setProdutos(resp);
          setPagination(response.data.totalPages);
          console.log(pagination);
        })
        .catch((err) => {
          setLoading(false);
          errorHandler(err);
        });
    }
    setLoading(true);
    axios
      .get(
        `${API_URL}/produto?page=${pg}&filtro=${search}&filtroSemImagem=${toggle}`,
        headers()
      )
      .then((response) => {
        setLoading(false);
        let resp = [];
        response.data.produtos.map((res) => {
          resp.push({
            codigo: res.codigo,
            nome: res.nome,
            unMedida: res.unidadeMedida,
            totalImg: res.totalImagem,
          });
        });
        setProdutos(resp);
        setPagination(response.data.totalPages);
      })
      .catch((err) => {
        setLoading(false);
        errorHandler(err);
      });
  }

  let qtdeTemp = produtos.length;
  if (qtdeTemp % 2 === 0) {
    qtdeTemp += 1;
  }

  function newPg() {
    setPg(page);
  }

  function changeTgl() {
    setToggle(!toggle);
  }

  const searchProducts = () => {
    if (searchValue.trim().length > 0) {
      setSearch(searchValue.trim());
    } else {
      setSearch("");
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          wrap="wrap"
          spacing={window.screen.width > 546 ? "" : 1}
        >
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            style={{
              marginTop: window.screen.width > 546 ? "-1.6rem" : "-2rem",
            }}
          >
            <h3 className={css(styles.pageTitle)}>Produtos</h3>
          </Grid>
          <Grid
            item
            xs={10.5}
            sm={4}
            md={4}
            lg={4.8}
            style={{
              marginTop: "-1.2rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              // label="Pesquisar"
              name="pesquisa"
              id="pesquisa"
              placeholder={"Pesquisar"}
              value={searchValue}
              onChange={(ev) => setSearchValue(ev.target.value)}
              type="search"
              // size="small"
              fullWidth
              className={css(styles.input)}
              sx={{
                marginTop: "0.3rem",
                "& .MuiInputBase-root": {
                  height: 38,
                  // fontFamily: "Poppins",
                  fontSize: 12,
                },
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  searchProducts();
                }
              }}
            ></TextField>
          </Grid>
          <Grid
            item
            xs={1.5}
            sm={1}
            md={1}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-1rem",
            }}
          >
            <button
              onClick={() => searchProducts()}
              className={css(styles.btn)}
              title="Buscar"
            >
              <SearchOutlinedIcon />
            </button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={3}
            style={{
              marginTop: window.screen.width > 546 ? "-1.8rem" : "-1.2rem",
              marginBottom: window.screen.width > 546 ? "" : "0.5rem",
              display: "flex",
              justifyContent:
                window.screen.width > 546 ? "flex-end" : "flex-start",
              alignItems: "center",
            }}
          >
            <p
              style={{
                marginRight: "0.4rem",
                // fontFamily: "Poppins",
                fontSize: 12,
              }}
            >
              Sem imagens
            </p>
            <label class="switch">
              <input type="checkbox" onChange={() => changeTgl()} />
              <span class="slider round"></span>
            </label>
          </Grid>
        </Grid>
        <hr
          style={{ color: "#eee", marginTop: "-0.55rem", marginBottom: "1rem" }}
        />
      </Box>

      {produtos && produtos.length > 0 && !loading ? (
        <Table>
          <Thead className="table_head">
            <Tr>
              <Th className="text-center">Código</Th>
              <Th className="text-center">Nome</Th>
              <Th className="text-center">U.M.</Th>
              <Th className="text-center">Imagens</Th>
              <Th className="text-center">Ações</Th>
            </Tr>
          </Thead>

          <Tbody className="table_body">
            {produtos.map((list) => {
              var parity = qtdeTemp % 2;
              qtdeTemp = qtdeTemp - 1;

              let info = {
                codigo: list.codigo,
                nome: list.nome,
                un: list.unMedida,
              };

              let num = 0;

              if (list.totalImg === 0) {
                num = "Sem imagens";
              } else {
                num = list.totalImg;
              }

              return (
                <Tr
                  key={`${list.codigo}`}
                  className={parity === 1 ? "odd_row" : "even_row"}
                >
                  <Td className="text-center">{list.codigo}</Td>
                  <Td className="text-center">{list.nome}</Td>
                  <Td className="text-center">{list.unMedida}</Td>
                  <Td className="text-center">{num}</Td>
                  <Td className="text-center" style={{ width: "10%" }}>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Link to={`/produtos-editar/${list.codigo}`} state={info}>
                        <BotaoAlterar />
                      </Link>
                    </span>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      ) : loading ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            // height={{ md: "20%", xs: "50%" }}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"#ECF9F2"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <div
              display={"flex"}
              style={{
                marginTop: "-0.5rem",
              }}
            >
              <CircularProgress color="success" />
            </div>
            <div display={"flex"}>
              <p
                style={{
                  color: "#4b4b4b",
                  fontSize: 14,
                  marginBottom: "-1rem",
                }}
              >
                Buscando informações
              </p>
            </div>
          </Grid>
        </Box>
      ) : (
        <Box
          width={"100%"}
          height={"60vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            height={{ md: "20%", xs: "50%" }}
            display={"inline-flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"lightyellow"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <p
              style={{
                color: "#4b4b4b",
                // fontFamily: "Poppins",
                fontSize: 14,
              }}
            >
              Sua pesquisa não retornou nenhum resultado.
            </p>
          </Grid>
        </Box>
      )}

      {pagination > 1 && (
        <Pagination
          showFirstButton
          showLastButton
          page={page}
          count={pagination}
          sx={{
            "& .MuiPaginationItem-root": {
              "&.Mui-selected": {
                background: Colors.mainLayoutAtiveBackground,
                color: "black",
              },
            },
            marginTop: "0.5rem",
          }}
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`/produtos${
                filter
                  ? `?page=${item.page}&filtro=${filter}`
                  : `?page=${item.page}`
              }`}
              {...item}
            />
          )}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,

    color: "#4b4b4b",
    // marginTop: "-0.15rem",
  },
  label: {
    // fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    textAlign: "left",
  },
  input: {
    marginBottom: "1rem",
    // fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.2rem 0.3rem 0 0.3rem",
    height: "50%",
    marginTop: "-1rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
});

export default Produtos;
