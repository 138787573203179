import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import CustomDatagrid from "../datagrid/CustomDatagrid";
import FilterMin from "../../../components/filter/FilterMin";

import { pdfSettings } from "../pdf/pdfSettings";
import PdfModal from "../../../components/modals/pdfModal";
import { CSVLink } from "react-csv";

import { Box, Grid, Pagination, PaginationItem, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from "@mui/icons-material/TableView";

import { StyleSheet, css } from "aphrodite";
import Colors from "../../../components/constants/Colors";

import { headers } from "../../../components/core/store/localStorage";
import errorHandler from "../../../components/core/store/errorHandler";
const API_URL = localStorage.getItem("apiUrl");

const currentUser = localStorage.getItem("userName");
const currentEmp = localStorage.getItem("empName");

function MovimentoFinanceiro() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");
  const filter = query.get("filtro");
  const [pagination, setPagination] = useState("");

  function startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  function lastOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  function startOfYear(date) {
    return new Date(date.getFullYear(), 0, 1);
  }
  function lastOfYear(date) {
    return new Date(date.getFullYear(), 11, 31);
  }

  const dt = new Date();

  const pgTitle = "Vendas por Meio de Pagamento";
  const fileTitle = "VENDAS_POR_MEIO_DE_PAGAMENTO";

  const isFirstRender = useRef(true);

  const [caixa, setCaixa] = useState("");
  const [cliente, setCliente] = useState("");
  const [inicio, setInicio] = useState(startOfMonth(dt));
  const [fim, setFim] = useState(dt);
  const [pagamento, setPagamento] = useState("");
  const [estabelecimentos, setEstabelecimentos] = useState("");
  const [tipoRef, setTipoRef] = useState("");
  const [ref, setRef] = useState("");
  const [equipe, setEquipe] = useState("");
  const [ordValue, setOrdValue] = useState("quantidade");
  const [ordTrigger, setOrdTrigger] = useState(false);
  const [orientation, setOrientation] = useState("");

  const [caixaList, setCaixaList] = useState([]);
  const [estList, setEstList] = useState([]);

  // const [pg, setPg] = useState(1);
  let pg = 1;
  const [head, setHead] = useState("");
  const [rows, setRows] = useState("");
  const [totals, setTotals] = useState("");
  const [periodo, setPeriodo] = useState(["day", "year"]);
  const [activeLabel, setActiveLabel] = useState("dia");

  const [fullHeadPDF, setFullHeadPDF] = useState("");
  const [fullReportPDF, setFullReportPDF] = useState("");
  const [columnPDF, setColumnPDF] = useState("");
  const [totalPDF, setTotalPDF] = useState("");
  const [fullHeadCSV, setFullHeadCSV] = useState("");
  const [fullReportCSV, setFullReportCSV] = useState("");

  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [pdfTrigger, setPdfTrigger] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [erro, setErro] = useState("");

  useEffect(() => {
    getCaixas();
    getEstabelecimentos();

    if (API_URL === null) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    getReportDataPDF();
    getReportDataCSV();
  }, [pg, trigger]);

  useEffect(() => {
    // setPg(page);
    pg = page;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getData();
  }, [page]);

  useEffect(() => {
    if (pdfTrigger) {
      pdfSettings(
        formatHeaders,
        fullHeadPDF,
        fullReportPDF,
        columnPDF,
        pgTitle,
        currentEmp,
        currentUser,
        cliente,
        formatIni,
        formatFim,
        ref,
        pagamento,
        tipoRef,
        estabelecimentos,
        equipe,
        dt,
        fileTitle,
        totalPDF,
        caixa,
        orientation
      );
      setPdfTrigger(false);
    }
  }, [pdfTrigger]);

  async function getData() {
    setLoading(true);

    let caixaLink = [];
    if (caixa !== "") {
      caixa.map((cx) => {
        caixaLink.push("'" + cx.value + "'");
      });
    } else {
      caixaLink = "";
    }
    let estLink = [];
    if (estabelecimentos !== "") {
      estabelecimentos.map((est) => {
        estLink.push("'" + est.value + "'");
      });
    } else {
      estLink = "";
    }

    await axios
      .get(
        `${API_URL}/pedido/gerencial/vendasmeiopagamento?page=${pg}&emissaoInicial=${
          inicio ? inicio.toLocaleString().split(",")[0] : ""
        }&emissaoFinal=${
          fim ? fim.toLocaleString().split(",")[0] : ""
        }&estabelecimentoId=${estLink}&caixaId=${caixaLink}&agrupar=${activeLabel}`,
        headers()
      )
      .then((response) => {
        setLoading(false);
        setHead(response.data.columns);
        setRows(response.data.data);
        setTotals(response.data.totalizador);
        setPagination(response.data.totalPages);
      })
      .catch((err) => {
        setLoading(false);
        errorHandler(err);
      });
    // setLoading(false);
  }

  function getReportDataPDF() {
    let caixaLink = [];
    if (caixa !== "") {
      caixa.map((cx) => {
        caixaLink.push("'" + cx.value + "'");
      });
    } else {
      caixaLink = "";
    }
    let estLink = [];
    if (estabelecimentos !== "") {
      estabelecimentos.map((est) => {
        estLink.push("'" + est.value + "'");
      });
    } else {
      estLink = "";
    }

    axios
      .get(
        `${API_URL}/pedido/gerencial/report/pdf/meiopagamento?page=${pg}&emissaoInicial=${
          inicio ? inicio.toLocaleString().split(",")[0] : ""
        }&emissaoFinal=${
          fim ? fim.toLocaleString().split(",")[0] : ""
        }&estabelecimentoId=${estLink}&caixaId=${caixaLink}&agrupar=${activeLabel}`,
        headers()
      )
      .then((response) => {
        setFullHeadPDF(response.data.headers);
        setFullReportPDF(response.data.data);
        setColumnPDF(response.data.columnStyles);
        setTotalPDF(response.data.totalizador);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function getReportDataCSV() {
    let caixaLink = [];
    if (caixa !== "") {
      caixa.map((cx) => {
        caixaLink.push("'" + cx.value + "'");
      });
    } else {
      caixaLink = "";
    }
    let estLink = [];
    if (estabelecimentos !== "") {
      estabelecimentos.map((est) => {
        estLink.push("'" + est.value + "'");
      });
    } else {
      estLink = "";
    }

    axios
      .get(
        `${API_URL}/pedido/gerencial/report/csv/meiopagamento?page=${pg}&emissaoInicial=${
          inicio ? inicio.toLocaleString().split(",")[0] : ""
        }&emissaoFinal=${
          fim ? fim.toLocaleString().split(",")[0] : ""
        }&estabelecimentoId=${estLink}&caixaId=${caixaLink}&agrupar=${activeLabel}`,
        headers()
      )
      .then((response) => {
        setFullHeadCSV(response.data.headers);
        setFullReportCSV(response.data.data);
      })
      .catch((err) => {
        console.log(err);
        errorHandler(err);
      });
  }

  function getCaixas() {
    console.log(`${API_URL}/caixa`);

    axios
      .get(`${API_URL}/caixa`, headers())
      .then((response) => {
        console.log(response);
        let resp = [];
        response.data.map((res) => {
          resp.push({
            value: res.id,
            label: res.descricao,
          });
        });
        setCaixaList(resp);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function getEstabelecimentos() {
    axios
      .get(`${API_URL}/estabelecimento`, headers())
      .then((response) => {
        let resp = [];
        response.data.map((res) => {
          resp.push({
            value: res.id,
            label: res.nome,
          });
        });
        setEstList(resp);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function updateCaixa(e) {
    setCaixa(e);
  }
  function updateInicio(e) {
    if (activeLabel === "ano") {
      setInicio(startOfYear(e));
    } else {
      setInicio(e);
    }
  }
  function updateFim(e) {
    if (activeLabel === "mes") {
      setFim(lastOfMonth(e));
    } else if (activeLabel === "ano") {
      setFim(lastOfYear(e));
    } else {
      setFim(e);
    }
  }
  function updateEstabelecimentos(e) {
    setEstabelecimentos(e);
  }

  function updateDay() {
    setInicio(startOfMonth(dt));
    setFim(dt);

    setActiveLabel("dia");
    setPeriodo(["day", "year"]);
  }
  function updateMonth() {
    setInicio(startOfMonth(dt));
    setFim(lastOfMonth(dt));

    setActiveLabel("mes");
    setPeriodo(["month", "year"]);
  }
  function updateYear() {
    setInicio(startOfYear(dt));
    setFim(lastOfYear(dt));

    setActiveLabel("ano");
    setPeriodo(["year"]);
  }

  const searchProducts = () => {
    if (inicio > fim) {
      setErro(
        "A data final deve ser posterior à data de início. Selecione o período novamente."
      );
    } else {
      setErro("");
      setTrigger(true);
      getData();
      getReportDataPDF();
      getReportDataCSV();
    }
  };

  const formatHeaders = [];
  const formatIni = inicio.toLocaleString().split(",")[0];
  const formatFim = fim.toLocaleString().split(",")[0];

  if (head) {
    head.forEach((hd) => {
      formatHeaders.push(hd.headerName);
    });
  }

  let totalChars = "";
  let headChars = "";

  if (totalPDF) {
    totalChars = totalPDF.join("").length;
  }
  if (formatHeaders) {
    headChars = formatHeaders.join("").length;
  }

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const retrato = () => {
    setOrientation("portrait");
    setPdfTrigger(true);
    setModalOpen(false);
  };
  const paisagem = () => {
    setOrientation("landscape");
    setPdfTrigger(true);
    setModalOpen(false);
  };

  const getPDF = () => {
    setPdfTrigger(true);
  };
  const getPDFLandscape = () => {
    setOrientation("landscape");
    setPdfTrigger(true);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container wrap="wrap">
          <Grid
            item
            xs={6}
            sm={7}
            md={8}
            lg={10}
            style={{ marginTop: "-1.6rem" }}
          >
            <h3 className={css(styles.pageTitle)}>{pgTitle}</h3>
          </Grid>
          <Grid
            item
            xs={6}
            sm={5}
            md={4}
            lg={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "-1.3rem",
            }}
          >
            <>
              {window.screen.width > 546 ? (
                <>
                  <Button
                    onClick={updateDay}
                    className={
                      loading
                        ? css(styles.dtBtnDisabled)
                        : activeLabel === "dia"
                        ? css(styles.dtBtnActive)
                        : css(styles.dtBtn)
                    }
                    style={{
                      height: "55%",
                      width: "5%",
                      marginRight: 5,
                      alignItems: "center",
                    }}
                    title="Definir período por dias"
                  >
                    <p>DIA</p>
                  </Button>
                  <Button
                    onClick={updateMonth}
                    className={
                      loading
                        ? css(styles.dtBtnDisabled)
                        : activeLabel === "mes"
                        ? css(styles.dtBtnActive)
                        : css(styles.dtBtn)
                    }
                    style={{
                      height: "55%",
                      width: "5%",
                      marginRight: 5,
                      alignItems: "center",
                    }}
                    title="Definir período por meses"
                  >
                    <p>MÊS</p>
                  </Button>
                  <Button
                    onClick={updateYear}
                    className={
                      loading
                        ? css(styles.dtBtnDisabled)
                        : activeLabel === "ano"
                        ? css(styles.dtBtnActive)
                        : css(styles.dtBtn)
                    }
                    style={{
                      height: "55%",
                      width: "5%",
                      marginRight: 10,
                      alignItems: "center",
                    }}
                    title="Definir período por anos"
                  >
                    <p>ANO</p>
                  </Button>
                </>
              ) : (
                ""
              )}
              {head && head.length > 0 ? (
                <>
                  <button
                    onClick={
                      totalChars > 85 || headChars > 90
                        ? getPDFLandscape
                        : openModal
                    }
                    className={css(styles.btn)}
                    style={{ marginRight: 5 }}
                    title="Exportar como PDF"
                  >
                    <PictureAsPdfIcon />
                  </button>
                  <CSVLink
                    headers={fullHeadCSV}
                    data={fullReportCSV}
                    separator=";"
                    // enclosingCharacter=""
                    filename={`VENDAS_POR_MEIO_DE_PAGAMENTO_${formatIni.replace(
                      /\//g,
                      "-"
                    )}_${formatFim.replace(/\//g, "-")}.csv`}
                    className={css(styles.btn)}
                    title="Exportar como CSV"
                  >
                    <TableViewIcon />
                  </CSVLink>
                </>
              ) : (
                ""
              )}
            </>
          </Grid>
        </Grid>
        <hr
          style={{ color: "#eee", marginTop: "-0.55rem", marginBottom: "1rem" }}
        />

        {window.screen.width < 546 ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "1.6rem",
              }}
            >
              <Button
                onClick={updateDay}
                className={
                  loading
                    ? css(styles.dtBtnDisabled)
                    : activeLabel === "dia"
                    ? css(styles.dtBtnActive)
                    : css(styles.dtBtn)
                }
                style={{
                  marginRight: 5,
                  alignItems: "center",
                  width: "80%",
                }}
                title="Definir período por dias"
              >
                <p>DIA</p>
              </Button>
              <Button
                onClick={updateMonth}
                className={
                  loading
                    ? css(styles.dtBtnDisabled)
                    : activeLabel === "mes"
                    ? css(styles.dtBtnActive)
                    : css(styles.dtBtn)
                }
                style={{
                  marginRight: 5,
                  alignItems: "center",
                  width: "80%",
                }}
                title="Definir período por meses"
              >
                <p>MÊS</p>
              </Button>
              <Button
                onClick={updateYear}
                className={
                  loading
                    ? css(styles.dtBtnDisabled)
                    : activeLabel === "ano"
                    ? css(styles.dtBtnActive)
                    : css(styles.dtBtn)
                }
                style={{
                  marginRight: 10,
                  alignItems: "center",
                  width: "80%",
                }}
                title="Definir período por anos"
              >
                <p>ANO</p>
              </Button>
            </div>

            <hr
              style={{
                color: "#eee",
                marginBottom: "1rem",
              }}
            />
          </>
        ) : (
          ""
        )}

        <FilterMin
          caixaValue={caixa}
          caixaSet={updateCaixa}
          caixaList={caixaList}
          iniValue={inicio}
          iniSet={updateInicio}
          fimValue={fim}
          fimSet={updateFim}
          estValue={estabelecimentos}
          estSet={updateEstabelecimentos}
          estList={estList}
          search={searchProducts}
          erro={erro}
          loading={loading}
          periodo={periodo}
        />
      </Box>

      {head && head.length > 0 && !loading ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "grid",
            }}
          >
            <CustomDatagrid
              head={head}
              row={rows}
              totals={totals}
              loading={loading}
            />
          </Box>

          {pagination > 1 && (
            <Pagination
              showFirstButton
              showLastButton
              page={page}
              count={pagination}
              sx={{
                "& .MuiPaginationItem-root": {
                  "&.Mui-selected": {
                    background: Colors.mainLayoutAtiveBackground,
                    color: "black",
                  },
                },
                marginTop: "0.5rem",
              }}
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`/vendas/meiopagamento${
                    filter
                      ? `?page=${item.page}&filtro=${filter}`
                      : `?page=${item.page}`
                  }`}
                  {...item}
                />
              )}
            />
          )}
        </>
      ) : loading ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            // height={{ md: "20%", xs: "50%" }}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"#ECF9F2"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <div
              display={"flex"}
              style={{
                marginTop: "-0.5rem",
              }}
            >
              <CircularProgress color="success" />
            </div>
            <div display={"flex"}>
              <p
                style={{
                  color: "#4b4b4b",
                  fontSize: 14,
                  marginBottom: "-1rem",
                }}
              >
                Buscando informações
              </p>
            </div>
          </Grid>
        </Box>
      ) : !loading && trigger ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            height={{ md: "20%", xs: "50%" }}
            display={"inline-flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"lightyellow"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <p
              style={{
                color: "#4b4b4b",
                // fontFamily: "Poppins",
                fontSize: 14,
              }}
            >
              Sua pesquisa não retornou nenhum resultado.
            </p>
          </Grid>
        </Box>
      ) : (
        ""
      )}

      <PdfModal
        modalOpen={modalOpen}
        retrato={retrato}
        paisagem={paisagem}
        toggle={closeModal}
      />
    </>
  );
}

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#4b4b4b",
  },
  head: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.5,
  },

  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 4,
    marginTop: "-0.5rem",
    cursor: "pointer",

    padding: "5px 8px 3px 8px",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },

  dtBtn: {
    backgroundColor: "#669966",
    color: "#fff",
    alignItems: "center",
    fontFamily: "Poppins",
    fontSize: window.screen.width > 546 ? 14 : 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 4,
    cursor: "pointer",
    marginTop: "-0.48rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  dtBtnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: window.screen.width > 546 ? 14 : 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 4,
    marginTop: "-0.48rem",
  },
  dtBtnActive: {
    backgroundColor: "transparent !important",
    color: "#669966",
    fontFamily: "Poppins",
    fontSize: window.screen.width > 546 ? 14 : 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966 !important",
    borderRadius: 4,
    marginTop: "-0.48rem",
  },
});

export default MovimentoFinanceiro;
