import { StyleSheet, css } from "aphrodite";
import { Oval } from "react-loader-spinner";
import { Dialog, Button, DialogTitle } from "@mui/material";

function Carregando(props) {
  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggle}
      PaperProps={{
        sx: {
          width: "20%",
          maxHeight: 300,
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", marginTop: 15 }}>
        <Oval
          height={60}
          width={60}
          color="#2cba8d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#2cba8d"
          strokeWidth={6}
          strokeWidthSecondary={6}
        />
      </div>
      <DialogTitle className={css(styles.title)}>Carregando</DialogTitle>
    </Dialog>
  );
}

export default Carregando;

const styles = StyleSheet.create({
  title: {
    // fontFamily: "Poppins",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 18,
  },
  text: {
    // fontFamily: "Poppins",
    textAlign: "center",
  },
});
