import React, { useState, useEffect } from "react";
import FilterAut from "../../../components/filter/FilterAut";
import BotaoDetalhes from "../../../components/buttons/btnDetails";
import BotaoAutoriza from "../../../components/buttons/btnAut";
import BotaoReprova from "../../../components/buttons/btnRep";
import BotaoInfo from "../../../components/buttons/btnInfo";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import formatNumberToCurrency from "../../../components/formaters/formatNumberToCurrency";

import InfoModal from "../../../components/modals/InfoModal";
import DetailsModal from "../../../components/modals/DetailsModal";
import AutorizaModal from "../../../components/modals/AutModal";
import ReprovaModal from "../../../components/modals/RepModal";

import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import { Box, Grid, Pagination, PaginationItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";

import { StyleSheet, css } from "aphrodite";
import Colors from "../../../components/constants/Colors";

import "../../../assets/styles/text.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { headers } from "../../../components/core/store/localStorage";
import errorHandler from "../../../components/core/store/errorHandler";
import { set } from "date-fns";

const API_URL = localStorage.getItem("apiUrl");

const currentUser = localStorage.getItem("userName");
const currentEmp = localStorage.getItem("empName");

function AutorizaVendas() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");

  function startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  const dt = new Date();

  const [pg, setPg] = useState(1);
  const [pagination, setPagination] = useState("");
  let orcID = "";
  // let clienteName = "";

  const [cliente, setCliente] = useState("");
  const [inicio, setInicio] = useState(startOfMonth(dt));
  const [fim, setFim] = useState(dt);
  const [numOrc, setNumOrc] = useState("");
  const [estabelecimentos, setEstabelecimentos] = useState("");
  const [clienteList, setClienteList] = useState([]);
  const [estList, setEstList] = useState([]);

  const [vendas, setVendas] = useState([]);
  const [dtHeader, setDtHeader] = useState("");
  const [dtItensList, setDtItensList] = useState("");
  const [generalInfo, setGeneralInfo] = useState("");
  const [generalDt, setGeneralDt] = useState("");
  const [dtTable1, setDtTable1] = useState("");
  const [dtTable2, setDtTable2] = useState("");

  const [details, setDetails] = useState("");
  const [ordValue, setOrdValue] = useState("P");
  const [position, setPosition] = useState("P");
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [erro, setErro] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [autOpen, setAutOpen] = useState(false);
  const [repOpen, setRepOpen] = useState(false);

  const [clienteName, setClienteName] = useState("");

  useEffect(() => {
    getData();
    getClientes();
    getEstabelecimentos();

    if (API_URL === null) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    setPg(page);
  }, [page]);

  function updateOrder(e) {
    setOrdValue(e.target.value);
  }

  async function getData() {
    setLoading(true);
    setTrigger(true);

    let clienteLink = [];
    if (cliente !== "") {
      cliente.map((cli) => {
        clienteLink.push("'" + cli.value + "'");
      });
    } else {
      clienteLink = "";
    }

    let estLink = [];
    if (estabelecimentos !== "") {
      estabelecimentos.map((est) => {
        estLink.push(est.value);
      });
    } else {
      estLink = "";
    }

    axios
      .get(
        `${API_URL}/orcamento?page=${pg}&situacaoId=${ordValue}&orcamentoId=${numOrc}&estabelecimentoId=${estLink}&clienteId=${clienteLink}&emissaoInicial=${
          inicio.toLocaleString().split(",")[0]
        }&emissaoFinal=${fim.toLocaleString().split(",")[0]}`,
        headers()
      )
      .then((response) => {
        setLoading(false);
        console.log(response);
        let resp = [];
        response.data.orcamentos.map((res) => {
          resp.push({
            id: res.id,
            orcamentoId: res.orcamentoId,
            cliente: res.cliente.nome,
            estabelecimentoId: res.estabelecimentoId,
            situacaoOrcamento: res.situacaoOrcamento,
            totalPedido: res.totalPedido,
            emissaoData: res.emissao.data,
            emissaoHora: res.emissao.hora,
            totalIpi: res.totalIpi,
          });
        });
        setVendas(resp);
        setPagination(response.data.totalPages);
        console.log(pagination);
      })
      .catch((err) => {
        setLoading(false);
        errorHandler(err);
      });
  }

  function getInfo() {
    axios
      .get(`${API_URL}/orcamento/custom/${orcID}`, headers())
      .then((response) => {
        console.log(response);

        let header = [];
        let info1 = [];
        let info2 = [];
        let itens = [];

        response.data.produto.map((res) => {
          info1.push({
            itemId: res.id,
            itemName: res.nome,
            unit: res.unidadeMedida,
          });
        });

        response.data.orcamento.itens.map((res) => {
          info2.push({
            itemId: res.produtoId,
            numItens: res.quantidade,
            precoBruto: res.unitario,
            desc: res.descontoItem.valor,
            precoFinal: res.unitario - res.descontoItem.valor,
            subtotal: res.totalLiquido,
            quantidade: res.quantidade,
            percentualIpi: res.percentualIpi,
            valorIpi: res.valorIpi,
          });
        });

        for (let i = 0; i < info1.length; i++) {
          itens.push({
            ...info1[i],
            ...info2.find((itmInner) => itmInner.itemId === info1[i].itemId),
          });
        }

        let itemLength = itens.length;

        header.push({
          numPedido: response.data.orcamento.orcamentoId,
          situacaoPedido: response.data.orcamento.status,

          nomeCliente: response.data.cliente.nome,
          cpfCnpj: response.data.cliente.cnpjCpf,
          cidade: response.data.cliente.endereco.localidade,
          uf: response.data.cliente.endereco.uf,

          quantidade: itemLength,
          totalBruto: response.data.orcamento.totais.produtos,
          descPercentual:
            response.data.orcamento.totais.descontos.cabecalho.percentual,
          descValor: response.data.orcamento.totais.descontos.cabecalho.valor,
          totalLiquido: response.data.orcamento.totais.total,
          totalIpi: response.data.orcamento.totalIpi,
        });

        setGeneralInfo(response.data.orcamento);
        setDtHeader(header);
        setDtItensList(itens);
        console.log(header);
        console.log(itens);
      })
      .catch((err) => {
        errorHandler(err);
        console.log(err);
      });
  }

  function getDetails() {
    axios
      .get(`${API_URL}/orcamentopendente/${orcID}`, headers())
      .then((response) => {
        console.log(response);

        let table1 = [];
        let table2 = [];

        response.data.ocorrenciaCabecalho.map((res) => {
          table1.push({
            id: res.id,
            oc: res.ocorrencia,
            tipoPendencia: res.tipoPendencia,
            valPadrao: res.valorReferencia1,
            valInformado: res.valorReferencia2,
            autorizado:
              res.autorizacaoCabecalho.autorizadorId !== "" ? true : false,
            checked: false,
          });
        });

        response.data.ocorrenciaItem.map((res) => {
          table2.push({
            id: res.id,
            ref: res.produtoId,
            oc: res.ocorrencia,
            valPadrao: res.valorReferencia1,
            valInformado: res.valorReferencia2,
            autorizado: res.autorizacaoItem.autorizadorId !== "" ? true : false,
            checked: false,
          });
        });

        setGeneralDt(response.data);
        setDtTable1(table1);
        setDtTable2(table2);
      })
      .catch((err) => {
        errorHandler(err);
        console.log(err);
      });
  }

  const searchVendas = () => {
    if (inicio > fim) {
      setErro(
        "A data final deve ser posterior à data de início. Selecione o período novamente."
      );
    } else {
      setErro("");
      setTrigger(true);
      getData();
      setPosition(ordValue);
    }
  };

  function openInfo(slctID, slctName) {
    orcID = slctID;
    setClienteName(slctName);

    getInfo();
    setInfoOpen(true);

    console.log(orcID);
  }
  const retornaInfo = () => {
    setInfoOpen(false);
  };

  function openDetails(slctID, slctName) {
    orcID = slctID;
    setClienteName(slctName);

    getDetails();
    setModalOpen(true);

    console.log(clienteName);
  }
  const retorna = () => {
    setModalOpen(false);
  };

  function openAutorizacao() {
    setAutOpen(true);
  }
  const cancelaAut = () => {
    setAutOpen(false);
  };

  function openReprovacao() {
    setRepOpen(true);
  }
  const cancelaRep = () => {
    setRepOpen(false);
  };

  function getClientes() {
    axios
      .get(`${API_URL}/cliente`, headers())
      .then((response) => {
        let resp = [];
        response.data.clientes.map((res) => {
          resp.push({
            value: res.id,
            label: res.estabelecimentoId + " | " + res.nome,
          });
        });
        setClienteList(resp);
      })
      .catch((err) => {
        console.log(err);
        errorHandler(err);
      });
  }

  function getEstabelecimentos() {
    axios
      .get(`${API_URL}/estabelecimento`, headers())
      .then((response) => {
        let resp = [];
        response.data.map((res) => {
          resp.push({
            value: res.id,
            label: res.nome,
          });
        });
        setEstList(resp);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function updateCliente(e) {
    setCliente(e);
  }
  function updateInicio(e) {
    setInicio(e);
  }
  function updateFim(e) {
    setFim(e);
  }
  function updateNumOrc(e) {
    setNumOrc(e);
  }
  function updateEstabelecimentos(e) {
    setEstabelecimentos(e);
  }

  let qtdeLinhas = vendas.length;
  if (qtdeLinhas % 2 === 0) {
    qtdeLinhas += 1;
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container wrap="wrap">
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            style={{ marginTop: "-1.6rem" }}
          >
            <h3 className={css(styles.pageTitle)}>Autorização de Vendas</h3>
          </Grid>
        </Grid>

        <hr
          style={{
            color: "#eee",
            marginTop: "-0.55rem",
            marginBottom: "1rem",
          }}
        />

        <FilterAut
          cliValue={cliente}
          cliSet={updateCliente}
          iniValue={inicio}
          iniSet={updateInicio}
          fimValue={fim}
          fimSet={updateFim}
          numOrcValue={numOrc}
          numOrcSet={updateNumOrc}
          estValue={estabelecimentos}
          estSet={updateEstabelecimentos}
          ordValue={ordValue}
          ordSet={updateOrder}
          clienteList={clienteList}
          estList={estList}
          search={searchVendas}
          erro={erro}
          loading={loading}
        />
      </Box>

      {vendas && vendas.length > 0 && !loading ? (
        <>
          <Box
            sx={{
              width: "100%",
              display: "grid",
            }}
          >
            <Table>
              <Thead className="table_head">
                <Tr>
                  <Th className="text-center">Número</Th>
                  <Th className="text-center">Nome do Cliente</Th>
                  <Th className="text-center">Estabelecimento</Th>
                  <Th className="text-center">Situação</Th>
                  <Th className="text-center">Total do Pedido</Th>
                  <Th className="text-center">Emissão</Th>
                  <Th className="text-center">IPI</Th>
                  <Th className="text-center" style={{ width: "3%" }}>
                    Ações
                  </Th>
                </Tr>
              </Thead>

              <Tbody className="table_body">
                {vendas.map((list) => {
                  var parity = qtdeLinhas % 2;
                  qtdeLinhas = qtdeLinhas - 1;

                  let selectedId = list.id;
                  let selectedName = list.cliente;

                  return (
                    <Tr
                      key={`${list.sequencial}`}
                      className={parity === 1 ? "odd_row" : "even_row"}
                    >
                      <Td
                        className={
                          list.situacaoOrcamento === "Autorizado"
                            ? css(styles.apText)
                            : // : list.dataRep !== ""
                              // ? css(styles.repText)
                              ""
                        }
                      >
                        {list.orcamentoId}
                      </Td>
                      <Td
                        className={
                          list.situacaoOrcamento === "Autorizado"
                            ? css(styles.apText)
                            : // : list.dataRep !== ""
                              // ? css(styles.repText)
                              ""
                        }
                      >
                        {list.cliente}
                      </Td>
                      <Td
                        className={
                          list.situacaoOrcamento === "Autorizado"
                            ? css(styles.apText)
                            : // : list.dataRep !== ""
                              // ? css(styles.repText)
                              ""
                        }
                      >
                        {list.estabelecimentoId}
                      </Td>
                      <Td
                        className={
                          list.situacaoOrcamento === "Autorizado"
                            ? css(styles.apText)
                            : // : list.dataRep !== ""
                              // ? css(styles.repText)
                              ""
                        }
                      >
                        {list.situacaoOrcamento}
                      </Td>
                      <Td
                        className={
                          list.situacaoOrcamento === "Autorizado"
                            ? css(styles.apText)
                            : // : list.dataRep !== ""
                              // ? css(styles.repText)
                              ""
                        }
                      >
                        {formatNumberToCurrency(list.totalPedido)}
                      </Td>
                      <Td
                        className={
                          list.situacaoOrcamento === "Autorizado"
                            ? css(styles.apText)
                            : // : list.dataRep !== ""
                              // ? css(styles.repText)
                              ""
                        }
                      >
                        {list.emissaoData} - {list.emissaoHora}
                      </Td>
                      <Td
                        className={
                          list.situacaoOrcamento === "Autorizado"
                            ? css(styles.apText)
                            : // : list.dataRep !== ""
                              // ? css(styles.repText)
                              ""
                        }
                      >
                        {list.totalIpi === 0 || list.totalIpi === null ? (
                          "-"
                        ) : (
                          <CheckIcon
                            className="icon-acoes"
                            style={{
                              width: "20px",
                              textAlign: "center",
                              paddingTop: 5,
                              color: "green",
                            }}
                          />
                        )}
                      </Td>
                      <Td style={{ width: "2%" }}>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent:
                              position === "K" ? "center" : "space-between",
                            alignContent: "center",
                          }}
                        >
                          <BotaoInfo
                            onClick={() => openInfo(selectedId, selectedName)}
                          />
                          {list.situacaoOrcamento === "Pendente" ? (
                            <BotaoDetalhes
                              onClick={() =>
                                openDetails(selectedId, selectedName)
                              }
                            />
                          ) : (
                            ""
                          )}

                          {/* <BotaoAutoriza onClick={() => openAutorizacao()} />
                          <BotaoReprova onClick={() => openReprovacao()} /> */}
                        </span>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

          {pagination > 1 && (
            <Pagination
              showFirstButton
              showLastButton
              page={page}
              count={pagination}
              sx={{
                "& .MuiPaginationItem-root": {
                  "&.Mui-selected": {
                    background: Colors.mainLayoutAtiveBackground,
                    color: "black",
                  },
                },
                marginTop: "0.5rem",
              }}
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`/orcamento/?page=${item.page}`}
                  {...item}
                />
              )}
            />
          )}
        </>
      ) : loading ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"#ECF9F2"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <div
              display={"flex"}
              style={{
                marginTop: "-0.5rem",
              }}
            >
              <CircularProgress color="success" />
            </div>
            <div display={"flex"}>
              <p
                style={{
                  color: "#4b4b4b",
                  fontSize: 14,
                  marginBottom: "-1rem",
                }}
              >
                Buscando informações
              </p>
            </div>
          </Grid>
        </Box>
      ) : !loading && trigger ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            height={{ md: "20%", xs: "50%" }}
            display={"inline-flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"lightyellow"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <p
              style={{
                color: "#4b4b4b",
                fontSize: 14,
              }}
            >
              Sua pesquisa não retornou nenhum resultado.
            </p>
          </Grid>
        </Box>
      ) : (
        ""
      )}

      <InfoModal
        modalOpen={infoOpen}
        close={retornaInfo}
        head={dtHeader}
        itemList={dtItensList}
        general={generalInfo}
        cliente={clienteName}
      />
      <DetailsModal
        modalOpen={modalOpen}
        close={retorna}
        table1={dtTable1}
        table2={dtTable2}
        general={generalDt}
        cliente={clienteName}
        // approve={autorizaVenda}
        // del={reprovaVenda}
      />
      <AutorizaModal
        modalOpen={autOpen}
        close={cancelaAut}
        // toggle={deleteImg}
      />
      <ReprovaModal
        modalOpen={repOpen}
        close={cancelaRep}
        // toggle={deleteImg}
      />
    </>
  );
}

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#4b4b4b",
  },
  head: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.5,
  },

  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 4,
    marginTop: "-0.5rem",
    cursor: "pointer",

    padding: "5px 8px 3px 8px",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  apText: {
    color: "green",
    textAlign: "center",
    alignContent: "center",
  },
  repText: {
    color: "red",
    textAlign: "center",
  },
});

export default AutorizaVendas;
