import { useEffect } from "react";

import OrderQueryTooltip from "../helpers/OrderQueryTooltip";
import formatNumberToCurrency from "../formaters/formatNumberToCurrency";

import { StyleSheet, css } from "aphrodite";
import {
  Dialog,
  Button,
  DialogTitle,
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Tooltip,
} from "@mui/material";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Colors from "../constants/Colors";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";

function InfoModal(props) {
  let general;
  let headerItems = "";
  let tableItems = "";
  let name;

  if (props.general) {
    general = props.general;
  }
  if (props.head) {
    headerItems = props.head;
  }
  if (props.itemList) {
    tableItems = props.itemList;
  }
  if (props.cliente) {
    name = props.cliente;
  }
  console.log(general);
  console.log(headerItems);
  console.log(tableItems);

  let qtdeLinhas = "";

  if (tableItems && tableItems !== "0") {
    qtdeLinhas = tableItems.length;
    if (qtdeLinhas % 2 === 0) {
      qtdeLinhas += 1;
    }
  }

  let situation;

  if (headerItems && headerItems[0].situacaoPedido === "K") {
    situation = "Autorizado";
  } else if (headerItems && headerItems[0].situacaoPedido === "R") {
    situation = "Reprovado";
  } else if (headerItems && headerItems[0].situacaoPedido === "P") {
    situation = "Pendente";
  } else {
    situation = "";
  }

  let compilado = [];
  let valoresFiltrados;

  /*Rotina de filtragem dos itens por classificação*/
  if (tableItems) {
    tableItems.forEach((element, index) => {
      const map = [];

      tableItems.forEach((result) => {
        if (result.unit === tableItems[index].unit) {
          map.push(result);
        }
      });

      compilado.push({
        unidade: element.unit,
        qntd: map.map(amount).reduce(sum),
      });
    });

    valoresFiltrados = compilado.reduce((unique, o) => {
      if (
        !unique.some((obj) => obj.unidade === o.unidade && obj.qntd === o.qntd)
      ) {
        unique.push(o);
      }
      return unique;
    }, []);

    function amount(item) {
      return item.quantidade;
    }
    function sum(prev, next) {
      return prev + next;
    }
  }
  /**/

  let identifiers;

  if (general) {
    identifiers = general.id.split("_");
    console.log(identifiers[0]);
  }

  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={props.close}
        PaperProps={{
          sx: {
            minWidth: 1200,
            height: 550,
            paddingRight: 2,
            paddingLeft: 2,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <DialogTitle className={css(styles.title)}>
            Informações do pedido - Orçamento Nº {general ? identifiers[1] : ""}{" "}
            {name ? ` - ${name}` : ""}
          </DialogTitle>
          <DialogTitle className={css(styles.estab)}>
            Estabelecimento - {general ? identifiers[0] : ""}
          </DialogTitle>
        </div>
        <hr
          style={{
            color: "#eee",
            marginTop: "-0.6rem",
            marginBottom: "0.5rem",
            width: "100%",
          }}
        />

        {headerItems !== "" && tableItems !== "" ? (
          <>
            <Stack spacing={0.5}>
              <Stack direction={"row"} spacing={0.5}>
                <Card sx={{ width: "40%" }}>
                  <CardContent
                    sx={{
                      padding: 0.5,
                      "&:last-child": {
                        paddingBottom: 0.25,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: Colors.titleRowWithBackgroundColor,
                        pl: 1,
                        display: "flex",
                        alignContent: "center",
                        paddingTop: 0.2,
                        paddingBottom: 0.2,
                      }}
                      variant="body2"
                      color={Colors.mainTextColor}
                    >
                      <ArticleOutlinedIcon
                        fontSize={"small"}
                        style={{ marginRight: 10 }}
                      />{" "}
                      Protocolo
                    </Typography>
                    <Stack
                      direction={{ md: "row", sm: "row", xs: "column" }}
                      justifyContent={{
                        md: "space-between",
                        sm: "space-between",
                        xs: "",
                      }}
                    >
                      <Typography
                        variant={"body2"}
                        style={{
                          display: "flex",
                          alignContent: "center",
                          paddingTop: 2,
                          paddingBottom: 1,
                        }}
                      >
                        <strong style={{ color: Colors.primary }}>
                          Pedido
                        </strong>{" "}
                        : {headerItems[0].numPedido}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
                <Card sx={{ width: "60%" }}>
                  <CardContent
                    sx={{
                      padding: 0.5,
                      "&:last-child": {
                        paddingBottom: 0.25,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        backgroundColor: Colors.titleRowWithBackgroundColor,
                        pl: 1,
                        display: "flex",
                        alignContent: "center",
                        paddingTop: 0.2,
                        paddingBottom: 0.2,
                      }}
                      variant="body2"
                      color={Colors.mainTextColor}
                    >
                      <CloudUploadOutlinedIcon
                        fontSize={"small"}
                        style={{ marginRight: 10 }}
                      />{" "}
                      Status do pedido
                    </Typography>
                    <Stack
                      direction={{ md: "row", sm: "row", xs: "column" }}
                      justifyContent={{
                        md: "space-between",
                        sm: "space-between",
                        xs: "",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          display: "flex",
                          alignContent: "center",
                          paddingTop: 2,
                          paddingBottom: 1,
                        }}
                      >
                        <strong>Situação</strong> : {situation}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>

              <Card>
                <CardContent
                  sx={{
                    padding: 0.5,
                    "&:last-child": {
                      paddingBottom: 0.25,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: Colors.titleRowWithBackgroundColor,
                      pl: 1,
                      display: "flex",
                      alignContent: "center",
                      paddingTop: 0.2,
                      paddingBottom: 0.2,
                    }}
                    variant="body2"
                    color={Colors.mainTextColor}
                  >
                    <PersonOutlineOutlinedIcon
                      fontSize={"small"}
                      style={{ marginRight: 10 }}
                    />{" "}
                    Cliente
                  </Typography>
                  <Stack
                    direction={{ md: "row", sm: "row", xs: "column" }}
                    justifyContent={{
                      md: "space-between",
                      sm: "space-between",
                      xs: "",
                    }}
                    style={{
                      display: "flex",
                      alignContent: "center",
                      paddingTop: 2,
                      paddingBottom: 1,
                    }}
                  >
                    <Typography variant="body2">
                      <strong style={{ color: Colors.primary }}>Nome</strong>:{" "}
                      {headerItems[0].nomeCliente}
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ md: "row", sm: "row", xs: "column" }}
                    justifyContent={{
                      md: "space-between",
                      sm: "space-between",
                      xs: "",
                    }}
                  >
                    <Typography variant="body2">
                      <strong style={{ color: Colors.primary }}>
                        CPF/CNPJ
                      </strong>
                      : {headerItems[0].cpfCnpj}
                    </Typography>

                    <Typography variant="body2">
                      <strong style={{ color: Colors.primary }}>
                        Cidade-UF
                      </strong>
                      : {headerItems[0].cidade}
                      {" - "}
                      {headerItems[0].uf}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>

              <Card>
                <CardContent
                  sx={{
                    padding: 0.5,
                    "&:last-child": {
                      paddingBottom: 0.25,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: Colors.titleRowWithBackgroundColor,
                      pl: 1,
                      display: "flex",
                      alignContent: "center",
                      paddingTop: 0.2,
                      paddingBottom: 0.2,
                    }}
                    variant="body2"
                    color={Colors.mainTextColor}
                  >
                    <LocalAtmOutlinedIcon
                      fontSize={"small"}
                      style={{ marginRight: 10 }}
                    />{" "}
                    Totais
                  </Typography>
                  <Stack
                    direction={{ md: "row", sm: "column", xs: "column" }}
                    justifyContent={{
                      md: "space-between",
                      sm: "space-between",
                      xs: "",
                    }}
                    style={{
                      display: "flex",
                      alignContent: "center",
                      paddingTop: 2,
                      paddingBottom: 1,
                    }}
                  >
                    <Typography variant="body2">
                      <strong style={{ color: Colors.primary }}>Itens</strong>:{" "}
                      {headerItems[0].quantidade}
                    </Typography>
                    <Typography variant="body2">
                      <strong style={{ color: Colors.primary }}>
                        Vr. Bruto
                      </strong>
                      : {formatNumberToCurrency(headerItems[0].totalBruto)}
                    </Typography>
                    <Typography variant="body2">
                      <strong style={{ color: Colors.primary }}>% Desc</strong>:{" "}
                      {headerItems[0].descPercentual.toFixed(2)}
                    </Typography>
                    <Tooltip arrow title={<OrderQueryTooltip />}>
                      <Typography variant="body2">
                        <strong style={{ color: Colors.primary }}>
                          Vr. Desc
                        </strong>
                        : {formatNumberToCurrency(headerItems[0].descValor)}
                      </Typography>
                    </Tooltip>
                    <Typography variant="body2">
                      <strong style={{ color: Colors.primary }}>
                        Vr. Líquido
                      </strong>
                      : {formatNumberToCurrency(headerItems[0].totalLiquido)}
                    </Typography>
                    {headerItems[0].totalIpi === 0 ||
                    headerItems[0].totalIpi === undefined ? (
                      ""
                    ) : (
                      <Typography variant="body2">
                        <strong style={{ color: Colors.primary }}>
                          Vr. IPI
                        </strong>
                        : {formatNumberToCurrency(headerItems[0].totalIpi)}
                      </Typography>
                    )}

                    {valoresFiltrados.map((result) => {
                      return (
                        <Typography variant="body2">
                          <strong style={{ color: Colors.primary }}>
                            {result.unidade}
                          </strong>{" "}
                          : {result.qntd}
                        </Typography>
                      );
                    })}
                  </Stack>
                </CardContent>
              </Card>
            </Stack>

            <Table style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Thead className="table_head">
                <Tr>
                  <Th style={{ textAlign: "left" }}>Referência</Th>
                  <Th style={{ textAlign: "left" }}>Descrição</Th>
                  <Th style={{ textAlign: "right" }}>Quantidade</Th>
                  {headerItems[0].totalIpi === 0 ||
                  headerItems[0].totalIpi === undefined ? (
                    ""
                  ) : (
                    <Th style={{ textAlign: "right" }}>Valor IPI</Th>
                  )}
                  {headerItems[0].totalIpi === 0 ||
                  headerItems[0].totalIpi === undefined ? (
                    ""
                  ) : (
                    <Th style={{ textAlign: "right" }}>Pr. + IPI</Th>
                  )}
                  <Th style={{ textAlign: "right" }}>Pr. Bruto</Th>
                  <Th style={{ textAlign: "right" }}>Desconto</Th>
                  <Th style={{ textAlign: "right" }}>Pr. Final</Th>
                  <Th style={{ textAlign: "right" }}>Subtotal</Th>
                </Tr>
              </Thead>

              <Tbody className="table_body">
                {tableItems.map((it) => {
                  var parity = qtdeLinhas % 2;
                  qtdeLinhas = qtdeLinhas - 1;

                  return (
                    <Tr
                      // key={`${it.codRefer}`}
                      className={parity === 1 ? "odd_row" : "even_row"}
                    >
                      <Td style={{ textAlign: "left" }}>{it.itemId}</Td>
                      <Td style={{ textAlign: "left" }}>{it.itemName}</Td>
                      <Td style={{ textAlign: "right" }}>{it.numItens}</Td>
                      {headerItems[0].totalIpi === 0 ||
                      headerItems[0].totalIpi === undefined ? (
                        ""
                      ) : (
                        <Td style={{ textAlign: "right" }}>
                          {it.valorIpi === 0 || it.valorIpi === undefined
                            ? "R$ 0,00"
                            : formatNumberToCurrency(it.valorIpi)}
                        </Td>
                      )}
                      {headerItems[0].totalIpi === 0 ||
                      headerItems[0].totalIpi === undefined ? (
                        ""
                      ) : (
                        <Td style={{ textAlign: "right" }}>
                          {it.valorIpi === 0 || it.valorIpi === undefined
                            ? formatNumberToCurrency(it.precoBruto)
                            : formatNumberToCurrency(
                                it.precoBruto + it.valorIpi
                              )}
                        </Td>
                      )}
                      <Td style={{ textAlign: "right" }}>
                        {formatNumberToCurrency(it.precoBruto)}
                      </Td>
                      <Td style={{ textAlign: "right" }}>
                        {formatNumberToCurrency(it.desc)}
                      </Td>
                      <Td style={{ textAlign: "right" }}>
                        {formatNumberToCurrency(it.precoFinal)}
                      </Td>
                      <Td style={{ textAlign: "right" }}>
                        {formatNumberToCurrency(it.subtotal)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </>
        ) : (
          ""
        )}

        <div style={{ height: "100%" }}></div>

        <div className={css(styles.buttonContainer)}>
          <Button
            variant="outlined"
            color="info"
            onClick={props.close}
            style={{ margin: 5 }}
          >
            Voltar
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default InfoModal;

const styles = StyleSheet.create({
  title: {
    // fontFamily: "Poppins",
    textAlign: "start",
    fontSize: 18,
    fontWeight: 700,
    // marginLeft: -25,
  },
  estab: {
    // fontFamily: "Poppins",
    textAlign: "end",
    fontSize: 18,
    fontWeight: 700,
    // marginRigth: -50,
  },
  text: {
    // fontFamily: "Poppins",
    textAlign: "center",
  },
  subtitle: {
    color: "#F2474A",
    fontSize: 13,
    fontWeight: 500,
    // fontFamily: "Poppins",
    textAlign: "center",
    marginTop: -10,
  },
  buttonContainer: {
    position: "static",
    bottom: 1,

    marginTop: "2%",
    marginBottom: "2%",
    display: "flex",
    justifyContent: "center",
  },
});
