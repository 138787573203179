import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import CreateIcon from "@mui/icons-material/Create";
import "../../assets/styles/botoes.css";

const BotaoAlterar = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  //   const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Button
        onClick={props.onClick}
        title="Editar"
        id="idApagar"
        className="btn-acoes btn-desativa btn-sm m-1"
      >
        <CreateIcon
          className="icon-acoes"
          style={{ width: "16px", textAlign: "center" }}
        />
      </Button>
    </>
  );
};

export default BotaoAlterar;
