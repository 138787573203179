import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import "../../assets/styles/botoes.css";

const BotaoInfo = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  //   const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Button
        onClick={props.onClick}
        title="Informações"
        id="idDetails"
        className="btn-acoes btn-info btn-sm m-1"
      >
        <RemoveRedEyeOutlinedIcon
          className="icon-acoes"
          style={{ width: "18px", textAlign: "center" }}
        />
      </Button>
    </>
  );
};

export default BotaoInfo;
