import { jsPDF } from "jspdf";
import "jspdf-autotable";

export function pdfSettings(
  formatHeaders,
  fullHeadPDF,
  fullReportPDF,
  columnPDF,
  pgTitle,
  currentEmp,
  currentUser,
  cliente,
  formatIni,
  formatFim,
  ref,
  pagamento,
  tipoRef,
  estabelecimentos,
  equipe,
  dt,
  fileTitle,
  totalPDF,
  caixa,
  orientation
) {
  const doc = new jsPDF(orientation);
  doc.setFontSize(10);

  console.log(pagamento);

  const totalRows = totalPDF;
  const tableColumn = formatHeaders;

  console.log(totalRows);

  let filter1 = "Class";
  let filter2 = "%AV";
  let filter3 = "Descrição";
  let filter4 = "Data";

  console.log(totalRows.filter((item) => item.length > 0));
  console.log(
    tableColumn.filter(
      (item) => !item.includes(filter1) && !item.includes(filter2)
    )
  );

  let listaCliente = [];
  let listaPag = [];
  let listaEqp = [];
  let listaTipoRef = [];
  let listaEstb = [];
  let listaCaixa = [];

  if (cliente)
    cliente.map((cli) => {
      listaCliente.push(" " + cli.label);
    });
  if (pagamento)
    pagamento.map((pag) => {
      listaPag.push(" " + pag.label.toUpperCase());
    });
  if (equipe)
    equipe.map((eqp) => {
      listaEqp.push(" " + eqp.label);
    });
  if (tipoRef)
    tipoRef.map((tr) => {
      listaTipoRef.push(" " + tr.label.toUpperCase());
    });
  if (estabelecimentos)
    estabelecimentos.map((est) => {
      listaEstb.push(" " + est.label);
    });
  if (caixa)
    caixa.map((cx) => {
      listaCaixa.push(" " + cx.label);
    });

  const addTitle = (doc) => {
    doc.setFontSize(16);
    doc.setTextColor(75, 75, 75);
    doc.text(pgTitle, 14, 12, {
      align: "left",
    });
    doc.setLineWidth(0.2);

    if (orientation === "landscape") {
      doc.line(14, 15, 283, 15);
    } else {
      doc.line(14, 15, 196, 15);
    }
  };

  const addInfo = (doc) => {
    doc.setFontSize(10);
    doc.setTextColor(75, 75, 75);

    if (orientation === "landscape") {
      doc.text(currentEmp.toUpperCase(), 283, 9, {
        align: "right",
      });
    } else {
      doc.text(currentEmp.toUpperCase(), 196, 9, {
        align: "right",
      });
    }

    if (orientation === "landscape") {
      doc.text(currentUser, 283, 13, {
        align: "right",
      });
    } else {
      doc.text(currentUser, 196, 13, {
        align: "right",
      });
    }
  };

  const addFooters = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(10);
    doc.setTextColor(75, 75, 75);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      if (orientation === "landscape") {
        doc.text("Página " + String(i) + " de " + String(pageCount), 14, 202, {
          align: "left",
        });
      } else {
        doc.text("Página " + String(i) + " de " + String(pageCount), 14, 289, {
          align: "left",
        });
      }

      if (orientation === "landscape") {
        doc.text(dt.toLocaleString(), 283, 202, {
          align: "right",
        });
      } else {
        doc.text(dt.toLocaleString(), 196, 289, {
          align: "right",
        });
      }
    }
  };

  const addLink = (doc) => {
    const pageCount = doc.internal.getNumberOfPages();

    doc.setFontSize(6);
    doc.setTextColor(75, 75, 75);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);

      if (orientation === "landscape") {
        doc.text("www.ivitech.com.br", doc.internal.pageSize.width / 2, 202, {
          align: "center",
        });
      } else {
        doc.text("www.ivitech.com.br", doc.internal.pageSize.width / 2, 288, {
          align: "center",
        });
      }
    }
  };

  addTitle(doc);
  addInfo(doc);

  let cliTable =
    listaCliente.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: 18,
          head: [["CLIENTES:" + [[listaCliente]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  doc.autoTable({
    theme: "plain",
    startY: listaCliente.length > 0 ? doc.lastAutoTable.finalY + 0.5 : 18,
    head:
      ref.length > 0
        ? [
            [
              `PERÍODO: ${formatIni + " - " + formatFim}` +
                "       " +
                `REFERÊNCIA: ${[[ref]]}`,
            ],
          ]
        : [[`PERÍODO: ${formatIni + " - " + formatFim}`]],
    styles: {
      halign: "left",
      cellPadding: 0.8,
      fontSize: 8,
      fontStyle: "normal",
      textColor: "#4b4b4b",
      marginBottom: 1,
    },
  });

  let pagTable =
    listaPag.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 0.5,
          head: [["COND. PAGAMENTO:" + [[listaPag]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  let tipoRefTable =
    listaTipoRef.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 0.5,
          head: [["TIPO DE REFERÊNCIA:" + [[listaTipoRef]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  let estbTable =
    listaEstb.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 0.5,
          head: [["ESTABELECIMENTOS:" + [[listaEstb]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  let eqpTable =
    listaEqp.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 0.5,
          head: [["EQUIPES:" + [[listaEqp]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  let caixaTable =
    listaCaixa.length > 0
      ? doc.autoTable({
          theme: "plain",
          startY: doc.lastAutoTable.finalY + 0.5,
          head: [["CAIXA:" + [[listaCaixa]]]],
          styles: {
            halign: "left",
            cellPadding: 0.8,
            fontSize: 8,
            fontStyle: "normal",
            textColor: "#4b4b4b",
            marginBottom: 1,
          },
        })
      : "";

  const teste = [
    {
      halign: "left",
    },
    {
      halign: "center",
    },
    {
      halign: "right",
    },
  ];

  doc.autoTable({
    startY: doc.lastAutoTable.finalY + 3,
    headStyles: { fillColor: [102, 153, 102] },
    head: [fullHeadPDF],
    body: fullReportPDF,
    columnStyles: columnPDF,
    alternateRowStyles: { fillColor: [241, 241, 241] },
  });

  doc.autoTable({
    head: [
      [
        {
          content: "TOTAL GERAL",
          styles: { halign: "center" },
        },
      ],
    ],
    headStyles: { fillColor: [102, 153, 102], minCellHeight: 0 },
    alternateRowStyles: { fillColor: [241, 241, 241] },
    styles: { cellPadding: 0.8 },
  });

  doc.autoTable(
    tableColumn.filter(
      (item) =>
        !item.includes(filter1) &&
        !item.includes(filter2) &&
        !item.includes(filter3) &&
        !item.includes(filter4)
    ),
    [totalRows.filter((item) => item.length > 0)],
    {
      startY: doc.lastAutoTable.finalY + 0,
      headStyles: { fillColor: [102, 153, 102], cellPadding: 0.8 },
      alternateRowStyles: { fillColor: [241, 241, 241] },
      styles: { halign: "center", cellPadding: 0.8 },
    }
  );
  addFooters(doc);
  addLink(doc);
  doc.save(
    `${fileTitle}_${formatIni.replace(/\//g, "-")}_${formatFim.replace(
      /\//g,
      "-"
    )}.pdf`
  );
}
