import { StyleSheet, css } from "aphrodite";
import { Dialog, Button, DialogTitle } from "@mui/material";

function ReprovaModal(props) {
  return (
    <>
      <Dialog
        open={props.modalOpen}
        onClose={props.close}
        PaperProps={{
          sx: {
            maxWidth: 700,
            maxHeight: 550,
            paddingRight: 5,
            paddingLeft: 5,
          },
        }}
      >
        <DialogTitle className={css(styles.title)}>Reprovar venda?</DialogTitle>
        <div className={css(styles.buttonContainer)}>
          <Button
            variant="outlined"
            color="error"
            onClick={props.toggle}
            style={{ margin: 5 }}
          >
            Reprovar
          </Button>
          <Button
            variant="outlined"
            color="info"
            onClick={props.close}
            style={{ margin: 5 }}
          >
            Voltar
          </Button>
        </div>
      </Dialog>
    </>
  );
}

export default ReprovaModal;

const styles = StyleSheet.create({
  title: {
    // fontFamily: "Poppins",
    textAlign: "center",
    fontWeight: 700,
    marginBottom: -5,
  },
  text: {
    // fontFamily: "Poppins",
    textAlign: "center",
  },
  subtitle: {
    color: "#F2474A",
    fontSize: 13,
    fontWeight: 500,
    // fontFamily: "Poppins",
    textAlign: "center",
    marginTop: -10,
  },
  buttonContainer: {
    marginTop: "3%",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "8%",
  },
});
