// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getRemoteConfig,
  fetchAndActivate,
  getValue,
} from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD_eJbeLcVv6MOIdKpZ2KfuVWOhQWfvUFM",
  authDomain: "siga-web-e11ce.firebaseapp.com",
  projectId: "siga-web-e11ce",
  storageBucket: "siga-web-e11ce.appspot.com",
  messagingSenderId: "461772345214",
  appId: "1:461772345214:web:fe7c286a768aa557be6d2f",
  measurementId: "G-D46BW84E07",

  // apiKey: "AIzaSyDjZ8kW0CdH-x2Xr8vRgX5Kjzm7UlHE0mw",
  // authDomain: "ivivenda-web.firebaseapp.com",
  // projectId: "ivivenda-web",
  // storageBucket: "ivivenda-web.appspot.com",
  // messagingSenderId: "416807885560",
  // appId: "1:416807885560:web:b35fb07b1639bcaae0790d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  server_url_test: "aplicacao.ivitech.com.br/hsiga",
};

export const storage = getStorage(app);

export const activateFirebaseConfig = async (
  suffix: string
): Promise<string> => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      await fetchAndActivate(remoteConfig);

      if (suffix === "dev") {
        const value = getValue(remoteConfig, "server_url_dev");
        resolve(`https://${value.asString()}/api/v1`);
      } else if (suffix === "teste") {
        const value = getValue(remoteConfig, "server_url_test");
        resolve(`https://${value.asString()}/api/v1`);
      } else if (suffix === "local") {
        resolve("http://192.168.3.116:8080/dsiga/api/v1");
      } else {
        const value = getValue(remoteConfig, "server_url_prod");
        resolve(`https://${value.asString()}/api/v1`);
      }
    } catch (err) {
      //console.log(err);
      reject({ message: "Network Error" });
    }
  });
};
